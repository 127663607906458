<template>
  <div>
    <div class="footer_contain">
      <div class="footer flex-wrap">
        <div>
          <img :src="require('@/assets/home/logo_write.png')" fit="cover" class="pointer logo" alt="" />
          <div class="font-fff-16 center">
            {{ $t('home.shop') }}
          </div>
        </div>
        <div class="flex-con"></div>
        <div class="m_r160">
          <div class="title1 relative">
            <span></span>
            {{ $t('home.help') }}
          </div>
          <div class="content pointer" @click="fnFwb(4)">
            {{ $t('home.buy') }}
          </div>
          <div class="content pointer" @click="fnFwb(5)">
            {{ $t('footAll.fwzc') }}
          </div>
          <div class="content pointer" @click="fnFwb(6)">
            {{ $t('footAll.sjbhzc') }}
          </div>
          <div class="content pointer" @click="fnFwb(7)">
            {{ $t('footAll.yszc') }}
          </div>
        </div>
        <div class="m_r160">
          <div class="title1 relative">
            <span></span>
            {{ $t('footAll.gywm') }}
          </div>
          <div class="content pointer" @click="fnFwb(1)">
            {{ $t('footAll.js') }}
          </div>
          <div class="content pointer" @click="fnFwb(2)">
            {{ $t('footAll.news') }}
          </div>
          <div class="content pointer" @click="fnFwb(3)">
            {{ $t('footAll.lxwm') }}
          </div>
        </div>
        <div class="">
          <div class="title1 relative">
            <span></span>
            {{ $t('home.language') }}
          </div>
          <div class="content1">
            {{ $t('home.eng') }}
          </div>
          <div class="content1">
            {{ $t('home.pro') }}
          </div>
          <div class="content1">
            {{ $t('home.spa') }}
          </div>

        </div>
      </div>
    </div>


    <div class="bg-fff1 center" style="padding: 30px 0 32px;">
      <div class="font-005BAC-18 align-center" style="margin-bottom: 27px;">
        <div class="pointer" @click="toMain()">{{ $t('home.page') }}</div>
        <div class="font-005BAC-14" style="margin:0 10px;">|</div>
        <div class="pointer" @click="fnFwb(1)">{{ $t('footAll.gywm') }}</div>
        <div class="font-005BAC-14" style="margin:0 10px;">|</div>
        <div class="pointer" @click="fnFwb(2)">{{ $t('footAll.news') }}</div>
        <div class="font-005BAC-14" style="margin:0 10px;">|</div>
        <div class="pointer" @click="fnFwb(3)">{{ $t('footAll.lxwm') }}</div>
      </div>
      <div class="font-999-16">{{ $t('home.copyright') }}</div>

    </div>



  </div>
</template>

<script>
import { firstGoodsCate, getConfig } from '../api'
export default {
  data() {
    return {
      addressZ: {},
      addressX: {},
    }
  },
  methods: {
    fnAddress(type) {
      let params = {
        type: type,
      }
      getConfig(params).then((data) => {
        if (data.status == 200) {
          if (type == 5) {
            this.addressZ = data.msg
          } else if (type == 6) {
            this.addressX = data.msg
          }
        }
      })
    },
    fnFwb(type) {

      switch (type) {
        case 1:
          this.$router.push({ name: 'intro' })

          break;
        case 2:
          this.$router.push({ name: 'news' })

          break;
        case 3:
          this.$router.push({ name: 'connect' })

          break;
        case 4:
          this.$router.push({ name: 'buy' })

          break;
        case 5:
          this.$router.push({ name: 'sPolicy' })

          break;
        case 6:
          this.$router.push({ name: 'dPolicy' })

          break;
        case 7:
          this.$router.push({ name: 'pPolicy' })

          break;
        default:
          break;
      }


    }
  },
  mounted() {
    this.fnAddress(5)
    this.fnAddress(6)
  },
}
</script>

<style scoped>
.logo {
  width: 136px;
  height: 28px;
  margin-bottom: 10px;
}

.footer_contain {
  background: #005BAC;
  box-sizing: border-box;
  padding: 40px 0;
  /* margin-top: 50px; */
}

.footer {
  width: 1200px;
  margin: auto;
}

.footer_item {
  width: 21.3%;
}

.footer_item:first-child {
  width: 36%;
}

.title {
  font-size: 22px;
  font-weight: 600;
  color: #FFFFFF;
  line-height: 30px;
  margin-bottom: 13px;
}

.content,
.content1 {
  font-size: 16px;
  color: #D6ECFF;
  line-height: 22px;
  margin-top: 10px;
}

.content:hover {
  color: #6FBBFF;
}

.title1 {
  font-weight: bold;
  font-size: 18px;
  color: #D6ECFF;
  margin-bottom: 33px;
}

.title1 span {
  position: absolute;
  width: 24px;
  height: 3px;
  background: #0087FF;
  left: 0;
  bottom: -15px;
}

.title2 {}
</style>
