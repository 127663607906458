import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueI18n from 'vue-i18n'
import English from './assets/i18/en.js'
import Spanish from './assets/i18/spa.js'
import Portuguese from './assets/i18/por.js'

Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$bus = new Vue()
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'en',
  messages: {
    en: English,
    es: Spanish,
    tp: Portuguese
  }
})

Vue.prototype.fnGoodsDetails = function (idd) {
  // this.$router.push({ name: 'productDetails', params: { id: idd } })
  window.open(
    this.$router.resolve({ name: 'productDetails', params: { id: idd } })
      .href,
    '_blank'
  )
}

// Vue.prototype.fnFwb = function (type) {
//   // this.$router.push({ name: 'aboutUs', params: { type: type } })
//   window.open(
//     this.$router.resolve({ name: 'aboutUs', params: { type: type } })
//       .href,
//     '_blank'
//   )
// }
Vue.prototype.toLogin = function () {
  this.$router.push({
    name: 'login'
  })
}
Vue.prototype.toMain = function () {
  this.$router.push({
    name: 'main'
  })
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
