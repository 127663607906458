<template>
    <div class="bg-fff">
        <Header />
        <div class="flex-wrap" style="height: 60px;width: 1200px;margin: 30px auto 42px;">
            <CateList />

            <div class="flex-con align-end font-999-16 bg-fff1 p_r18">
                {{ $t('logon.local') }} > {{ $t('other.cart') }}
            </div>

        </div>
        <div class="box margin">

            <div class="font-005BAC-24-500 bold" style="margin-bottom:25px;">
                <div>{{ $t('carObj.cpqd').toUpperCase() }} <span class="xian1"></span> </div>
            </div>
            <div class="flex-wrap align-between center box2 font-333-20-500">
                <!-- <div style="width:104px;" class="left align-start">
                    <input type="checkbox" name="allCheckbox" value="" id="allCheck" @change="allCheck" />
                    {{ $t('carObj.qx') }}
                </div> -->
                <div class="flex-con left" style="padding-left:90px;">{{ $t('carObj.cpxx') }}</div>
                <div style="width:140px;margin-right:20px;text-align: left;">{{ $t('orderObj.gg') }}</div>
                <div style="width:160px;margin-right:40px;">{{ $t('carObj.je') }}</div>
                <!-- <div style="width:144px;">{{ $t('carObj.dj') }}</div> -->
                <div style="width:180px;margin-right:16px;">{{ $t('carObj.sl') }}</div>
                <div style="width:170px;">{{ $t('orderObj.cz') }}</div>

            </div>
            <div class="four-box">
                <div class="order-item m_t12" v-for="(item, index) in ShopCarListData" :key="item.sc_id">
                    <div class="box5 flex-wrap align-between center">
                        <!-- <div style="width:104px;" class="align-start">
                            <input type="checkbox" name="checkbox" value="" class="checkbox" @change="oneCheck" @click.stop
                                :data-price="((item.spec_cu_price * item.sc_number * 100) / 100).toFixed(2)
                                    " :data-idd="item.sc_id" :id="'check_' + item.sc_id" />
                        </div> -->
                        <div class="flex-con left">
                            <div class="align-start">
                                <img class="img1" :src="item.goods_thumb" alt="">
                                <div class="font-333-14" style="width:215px;">{{ $i18n.locale == 'en' ?
                                    item.goods_name_en
                                    : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}</div>
                            </div>
                        </div>
                        <div class="font-333-16" style="width:140px;margin-right:20px;text-align: left;">
                            {{ item.packaging_name }} <br> {{ item.spec_value }}
                        </div>
                        <div style="width:160px;margin-right:40px;">
                            $ {{
                                ((item.spec_cu_price * item.sc_number * 100) / 100).toFixed(2)
                            }}
                        </div>
                        <!-- <div class="font-333-16" style="width:144px;">
                            $ {{ item.spec_cu_price }}
                        </div>  -->
                        <div style="width:180px;margin-right:16px;">
                            <el-input-number v-model="item.sc_number" @change="(val) => {
                                handleChange(
                                    val,
                                    item.sc_id,
                                    index,
                                    ((item.spec_cu_price * item.sc_number * 100) / 100).toFixed(2)
                                )
                            }
                                " :min="1"></el-input-number>
                        </div>

                        <div style="width:170px;">
                            <img class="pointer" style="min-width: 40px; height: 40px;"
                                :src="require('@/assets/delete1.png')" fit="cover" alt=""
                                @click="fnDelete(item.sc_id)" />
                        </div>


                    </div>
                </div>
            </div>


            <div class="font-9c9c9c-24 align-end" style="margin-top: 30px;">
                <div>{{ $t('orderObj.goodsPrice') }}:</div>
                &nbsp;<div class="font-005BAC-24-bold">{{ $t('JCs') }}</div>
                &nbsp;<div class="font-005BAC-36-bold relative" style="bottom:5px;">${{ allPrice }}</div>
            </div>
            <div class="align-end">
                <div class="align-center yellow1">
                    <img style="min-width: 56px; height: 56px;margin-right: 30px;" :src="require('@/assets/tip.png')"
                        fit="cover" alt="" />
                    <div>
                        {{ $t('carObj.noOtherPrice') }} <br> {{ $t('carObj.calcPrice') }}
                    </div>
                </div>
            </div>

            <div class="font-005BAC-24-500 bold" style="margin:40px 0 36px;">
                <div>{{ $t('carObj.confirmSA').toUpperCase() }} <span class="xian1"></span> </div>
            </div>

            <div class="title2 align-center pointer" style="margin-bottom: 37px;" v-show="!addressObj"
                @click="fnAddAddress">
                <img :src="require('@/assets/jia1.png')" alt="" class="jia1">
                <div>{{ $t('addressObj.addAddress') }}</div>
            </div>



            <div class="align-top font-666-16 relative border_eee" style="padding: 10px 28px;line-height: 26px;"
                v-if="addressObj">
                <div class="xian2"></div>
                <div style="width:308px;">
                    <div class="align-start">
                        <div class="bold">{{ $t('logon.d131') }}:</div> &nbsp;
                        <div>{{ addressObj.addr_name }}</div>
                    </div>
                    <div class="align-start">
                        <div class="bold">{{ $t('orderObj.address') }}:</div> &nbsp;
                        <div>{{ addressObj.addr_address }}</div>
                    </div>
                </div>
                <div style="width:320px;">
                    <div class="align-start">
                        <div class="bold">{{ $t('xjd.mdg') }}:</div> &nbsp;
                        <div>{{ addressObj.addr_mdg }}</div>
                    </div>
                    <div class="align-start">
                        <div class="bold">{{ $t('orderObj.emailOnly') }}:</div> &nbsp;
                        <div>{{ addressObj.addr_postal }}</div>
                    </div>
                </div>
                <div style="width:380px;">
                    <div class="align-start">
                        <div class="bold">{{ $t('xjd.mysysj') }}:</div> &nbsp;
                        <div>{{ addressObj.addr_mysysj }}</div>
                    </div>
                    <div class="align-start">
                        <div class="bold">{{ $t('xjd.ysfs') }}:</div> &nbsp;
                        <div>{{ addressObj.addr_paytype }}</div>
                    </div>
                </div>

                <div class="pointer edit1" @click="fnAddAddress">
                    {{ $t('addressObj.edit') }}
                </div>


            </div>
            <div class="font-005BAC-24-500 bold" style="margin:20px 0 20px;">
                <div>{{ $t('orderObj.sRequire').toUpperCase() }} <span class="xian1"></span> </div>
            </div>

            <textarea class="flex-con" :placeholder="$t('orderObj.sRequireXq')" v-model="order_intro"></textarea>

            <div class="align-end" v-show="ShopCarListData.length > 0">
                <div class="title2 align-center pointer" style="margin-top: 58px;" @click="fnSubmit()">
                    <img :src="require('@/assets/sure.png')" alt="" class="jia1">
                    <div>{{ $t('carObj.tjdd') }}</div>
                </div>
            </div>
            <el-dialog :title="$t('carObj.tip')" :visible.sync="dialogVisible" width="30%" center :show-close="false">
                <div class="font-333" style="text-align: center; font-size: 20px">
                    {{ $t('carObj.qrsc') }}？
                </div>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="dialogVisible = false">{{ $t('carObj.qux') }}</el-button>
                    <el-button type="primary" @click="fnCun">{{ $t('carObj.qr') }}</el-button>
                </span>
            </el-dialog>
        </div>
        <Icon />
        <Footer />
    </div>
</template>

<script>
import CateList from '@/components/CateList.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Icon from '@/components/Icon.vue'
import { shopCarList, setNumber, delShopCar, showAddress, shopCarCommitOrder } from '../api'
export default {
    components: {
        Header,
        Footer,
        CateList,
        Icon
    },
    data() {
        return {
            dialogVisible: false,
            ShopCarListData: [],
            idd: '',
            sc_ids: '',
            allPrice: '',
            addressObj: {},
            order_intro: ''
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            console.log(from.name);
            vm.fromName = from.name
        })
    },
    mounted() {
        this.initShopCarList()
        // this.fromName=='car' || this.fromName=='productDetails' || 
        if (this.fromName == 'myAddress') {
            this.addressObj = JSON.parse(localStorage.getItem('wlAddressPc'));
            this.order_intro = localStorage.getItem('order_intro');

        } else {
            localStorage.setItem('wlAddressPc', '')
            this.initShowAddress()
        }
    },
    methods: {
        initShowAddress() {
            let params = {}
            showAddress(params).then((data) => {
                if (data.status == 200) {
                    this.addressObj = data.msg
                }
            })
        },
        fnAddAddress() {
            localStorage.setItem('order_intro', this.order_intro)
            this.$router.push({
                path: 'myAddress',
                query: {
                    from: 1
                },
            })
        },

        fnSubmit() {
            let sc_id1 = []
            if (!this.addressObj) {
                this.$message.error(this.$t('carObj.qxzdz'))
                return
            }
            if (this.ShopCarListData.length > 0) {

                this.ShopCarListData.forEach((element) => {
                    sc_id1.push(element.sc_id)
                })
            }
            let params = {
                sc_ids: sc_id1.join(','),
                addr_id: this.addressObj.addr_id,
                order_remark: this.order_intro
            }
            shopCarCommitOrder(params).then((data) => {
                if (data.status == 200) {
                    this.$router.push({
                        name: 'orderCenter',
                        params: {
                            id: 1
                        },
                    })
                }
            })
        }
        ,
        fnDelete(sc_id) {
            this.idd = sc_id
            this.dialogVisible = true
        },
        fnCun() {
            let params = {
                sc_ids: this.idd,
            }
            delShopCar(params).then((data) => {
                if (data.status == 200) {
                    this.dialogVisible = false
                    this.$message.success(data.msg)
                    this.initShopCarList()
                    this.$bus.$emit('carNum111')

                }
            })
        },
        initShopCarList() {
            let params = {}
            shopCarList(params).then((data) => {
                if (data.status == 200) {
                    this.ShopCarListData = data.msg
                    this.allPrice = 0
                    this.ShopCarListData.forEach((element) => {
                        this.allPrice = (((Number(element.spec_cu_price) * Number(element.sc_number) + Number(this.allPrice)) * 100) / 100).toFixed(2)
                    })
                }
            })
        },
        // oneCheck(e) {
        //     // console.log(e.target.checked)
        //     let allCk1 = document.querySelectorAll("input[name='checkbox']")
        //     let allCk2 = document.querySelectorAll("input[name='checkbox']:checked")
        //     if (allCk1.length == allCk2.length && allCk2.length > 0) {
        //         document.querySelector('#allCheck').checked = true
        //     } else if (allCk1.length != allCk2.length) {
        //         document.querySelector('#allCheck').checked = false
        //     }
        //     this.allPrice = 0
        //     for (let index = 0; index < allCk2.length; index++) {
        //         this.allPrice = ((Number(allCk2[index].dataset.price) * 100 + Number(this.allPrice) * 100) / 100).toFixed(2)
        //     }
        // },
        // allCheck(e) {
        //     let allCk = document.querySelectorAll("input[name='checkbox']")
        //     if (allCk.length > 0) {
        //         this.allPrice = 0
        //         allCk.forEach((element) => {
        //             if (e.target.checked) {
        //                 element.checked = true
        //                 this.allPrice = ((Number(element.dataset.price) * 100 + Number(this.allPrice) * 100) / 100).toFixed(2)
        //             } else {
        //                 element.checked = false
        //             }
        //         })
        //     }
        // },
        handleChange(value, id, index, price) {
            let params = {
                number: value,
                sc_id: id,
            }
            setNumber(params).then((data) => {
                if (data.status == 200) {
                    this.$bus.$emit('carNum111')
                    this.ShopCarListData[index].sc_number = value
                    this.allPrice = 0
                    this.ShopCarListData.forEach((element) => {
                        this.allPrice = (((Number(element.spec_cu_price) * Number(element.sc_number) + Number(this.allPrice)) * 100) / 100).toFixed(2)
                    })
                    // (item.spec_cu_price * item.sc_number * 100) / 100).toFixed(2)
                    // if (document.getElementById('check_' + id).checked) {
                    //     let allCk2 = document.querySelectorAll(
                    //         "input[name='checkbox']:checked"
                    //     )
                    //     this.allPrice = 0
                    //     allCk2.forEach((element) => {
                    //         this.allPrice = ((Number(element.dataset.price) * 100 + Number(this.allPrice) * 100) / 100).toFixed(2)
                    //     })
                    // }
                }
            })
        }
    }

}
</script>

<style scoped>
.btn2 {
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    background: #018ECF;
    border-radius: 4px;
    height: 59px;
}

.btn1 {
    width: 249px;
    height: 59px;
    border-radius: 4px;
    border: 1px solid #979797;
}

input[type='checkbox'] {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid #979797;
    border-radius: 2px;
    margin-right: 16px;
}

input[type='checkbox']:checked {
    border: 2px solid #018ECF;
    background: url('@/assets/checked1.png') no-repeat center center;
    background-size: 18px 18px;
}

.box2 {
    height: 42px;
    background: #005BAC;
    line-height: 42px;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    margin-top: 32px;
    margin-bottom: 18px;
}

/* .box5 {
    padding: 10px 12px 12px;
} */

.box5 img.img1 {
    width: 78px;
    height: 70px;
    min-width: 78px;
    margin-right: 12px;
}

.order-item {
    margin-bottom: 15px;
    border: 1px solid #EEEEEE;
    padding: 24px 0 26px 20px;
}

.order-item:hover {
    background: #F8F8F8;
}

.box3 {
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid #979797;
    min-width: 550px;
}

.four-box {
    /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); */
}

.box {
    padding: 30px 50px 60px;
    width: 1200px;
    background-color: #FFFFFF;
    box-sizing: border-box;
}

/* 加减数 */


/* 加减数 */
::v-deep .el-input-number {
    width: 100%;
    height: 42px;
    border: none;
    line-height: 42px;
}

::v-deep .el-input-number__increase {
    border-left: none;
    width: 42px;
    height: 42px;
    background: none;
    font-size: 20px;
}

::v-deep .el-input-number__decrease {
    background: none;
    border-right: none;
    width: 42px;
    height: 42px;
    font-size: 20px;
}

::v-deep .el-input-number .el-input {
    height: 42px;
}

::v-deep .el-input-number .el-input__inner {
    padding-left: 0;
    padding-right: 0;
    border: none;
    height: 42px;
    font-size: 24px;
    border: 1px solid #e5e5e5;
    width: 100%;
    line-height: 42px;
}

::v-deep .el-input__inner {
    border-radius: 21px;
    color: #003275;
    background: #fff;
}

::v-deep [class*=" el-icon-"],
::v-deep [class^=el-icon-] {
    font-family: element-icons !important;
    speak: none;
    font-style: normal;
    font-weight: bold;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


::v-deep .el-input-number__decrease:hover,
::v-deep .el-input-number__increase:hover {
    color: #003275
}

::v-deep .el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled),
::v-deep .el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) {
    border-color: #003275;
}

::v-deep .el-input-number__decrease.is-disabled,
::v-deep .el-input-number__increase.is-disabled {
    color: #C0C4CC;
    cursor: not-allowed
}



/* dialog */

::v-deep .el-dialog {
    border-radius: 6px;
}

::v-deep .el-button:hover {
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
}

::v-deep .el-button--primary,
.el-button--primary:hover {
    color: #FFF;
    background-color: #005BAC;
    border-color: #005BAC;
}


.yellow1 {
    padding: 14px 12px 16px 18px;
    background: #F9BE00;
    font-size: 16px;
    color: #000000;
    line-height: 26px;
    margin-top: 20px;
}

.title2 {
    width: 240px;
    height: 48px;
    background: #005BAC;
    border-radius: 24px;
    font-size: 16px;
    color: #FFFFFF;
    margin-top: 28px;
}

.jia1 {
    width: 24px;
    height: 24px;
    margin-right: 24px;
}

textarea {
    width: 100%;
    min-height: 78px;
    border: 1px solid #eee;
    padding: 15px;
    font-size: 16px;
    color: #333;
    outline: none;
    box-sizing: border-box;
    font-family: "微软雅黑";
}

textarea::-webkit-input-placeholder {
    color: #999999;
    /* font-family: "微软雅黑"; */
}

.xian2 {
    position: absolute;
    margin: auto;
    left: 11px;
    top: 12px;
    width: 6px;
    height: 18px;
    background: #F9BE00;
}

.edit1 {
    height: 25px;
    position: absolute;
    margin: auto;
    right: 37px;
    top: 0;
    bottom: 0;
    font-size: 16px;
    color: #005BAC;
}
</style>