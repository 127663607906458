<template>
    <div>
        <Header />
        <div class="flex-wrap" style="height: 60px;width: 1200px;margin: 30px auto 42px;">
            <CateList />

            <div class="flex-con align-end font-999-16 bg-fff1 p_r18">
                {{ $t('logon.local') }} > {{ $t('other.confirmOrder') }}
            </div>

        </div>





        <!-- <div class="box">
            <div class="flex-wrap">
                <div class="box1 m_r46">
                    <div class="title2 align-start" style="padding:0;">
                       {{ $t('footAll.dz') }}
                    </div>
                    <div class="box4 font-333-22 pointer" v-show="addressObj.addr_id" style="margin-bottom: 40px;"
                        @click="fnSelectAddress">
                        <div class="font-018ECF-24-500 m_b30"> {{ addressObj.addr_xing }}{{ addressObj.addr_name }} </div>
                        <div class="m_b12">{{ addressObj.addr_address }} （{{ addressObj.addr_postal }}）</div>
                        <div class="">{{ addressObj.addr_phone }}</div>
                    </div>
                    <div class="title2 align-start pointer" v-show="!addressObj.addr_id" style="padding:0;height: auto;"
                        @click="fnSelectAddress">
                        <img :src="require('@/assets/jia1.png')" alt="" class="jia1">
                        <div>{{ $t('carObj.tjdz') }}</div>
                    </div>
                </div>

                <div class="flex-con">
                    <div class="box2">
                        <div class="title2 b-b-979797 align-start">
                            {{ $t('carObj.cpqd') }}
                        </div>
                        <div class="box3">
                            <div class="goods-item align-start font-333-24" v-for="(item, index) in data.msg" :key="index">
                                <img :src="item.goods_thumb" alt="">
                                <div class="font-333-24-500" style="width:38%;">{{ $i18n.locale == 'en' ?
                                    item.goods_name_en
                                    : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}</div>
                                <div style="width:15%;">{{ item.packaging_name }} <br> {{ item.spec_value }}</div>
                                <div style="width:10%;">X {{ item.sc_number || goods_quantity}}</div>
                                <div>$ {{ item.spec_cu_price }}</div>
                            </div>
                        </div>
                        <div class="title2 b-t-979797 align-between">
                            <div>{{ $t('goodsObj.zj') }}</div>
                            <div>$ {{ data.totalPrice }}</div>
                        </div>
                    </div>
                    <div class="align-end">
                        <div class="btn align-center pointer" @click="initCommitOrder">{{ $t('carObj.tjdd') }}</div>
                    </div>
                </div>
            </div>
        </div> -->


        <div class="box margin">

<div class="font-005BAC-24-500 bold" style="margin-bottom:25px;">
    <div>{{ $t('carObj.cpqd').toUpperCase() }} <span class="xian1"></span> </div>
</div>

<div class="flex-wrap align-between center box2 font-333-20-500">

    <div class="flex-con left" style="padding-left:90px;">{{ $t('carObj.cpxx') }}</div>
    <div style="width:140px;margin-right:20px;text-align: left;">{{ $t('orderObj.gg') }}</div>
    <div style="width:280px;margin-right:16px;">{{ $t('carObj.sl') }}</div>
    
    <div style="width:160px;margin-right:40px;">{{ $t('carObj.dj') }}</div>

</div>

<div class="four-box">
    <div class="order-item m_t12" v-for="(item, index) in data.msg" :key="index">
        <div class="box5 flex-wrap align-between center">
            <div class="flex-con left">
                <div class="align-start">
                    <img class="img1" :src="item.goods_thumb" alt="">
                    <div class="font-333-14" style="width:215px;">{{ $i18n.locale == 'en' ?
                        item.goods_name_en
                        : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}</div>
                </div>
            </div>
            <div class="font-333-16" style="width:140px;margin-right:20px;text-align: left;">
                {{ item.packaging_name }} <br> {{ item.spec_value }}
            </div>
            
            
            <div style="width:280px;margin-right:16px;">
                {{goods_quantity}}
            </div>

            <div style="width:160px;margin-right:40px;">
                $ {{item.spec_cu_price}}
            </div>


        </div>
    </div>
</div>


<div class="font-9c9c9c-24 align-end" style="margin-top: 30px;">
    <div>{{ $t('orderObj.goodsPrice') }}:</div>
    &nbsp;<div class="font-005BAC-24-bold">{{ $t('JCs') }}</div>
    &nbsp;<div class="font-005BAC-36-bold relative" style="bottom:5px;">${{ data.totalPrice }}</div>
</div>
<div class="align-end">
    <div class="align-center yellow1">
        <img style="min-width: 56px; height: 56px;margin-right: 30px;" :src="require('@/assets/tip.png')"
            fit="cover" alt="" />
        <div>
            {{ $t('carObj.noOtherPrice') }} <br> {{ $t('carObj.calcPrice') }}
        </div>
    </div>
</div>

<div class="font-005BAC-24-500 bold" style="margin:40px 0 36px;">
    <div>{{ $t('carObj.confirmSA').toUpperCase() }} <span class="xian1"></span> </div>
</div>

<div class="title2 align-center pointer" style="margin-bottom: 37px;" v-show="!addressObj"
    @click="fnSelectAddress">
    <img :src="require('@/assets/jia1.png')" alt="" class="jia1">
    <div>{{ $t('addressObj.addAddress') }}</div>
</div>



<div class="align-top font-666-16 relative border_eee" style="padding: 10px 28px;line-height: 26px;"
    v-if="addressObj">
    <div class="xian2"></div>
    <div style="width:308px;">
        <div class="align-start">
            <div class="bold">{{ $t('logon.d131') }}:</div> &nbsp;
            <div>{{ addressObj.addr_name }}</div>
        </div>
        <div class="align-start">
            <div class="bold">{{ $t('orderObj.address') }}:</div> &nbsp;
            <div>{{ addressObj.addr_address }}</div>
        </div>
    </div>
    <div style="width:320px;">
        <div class="align-start">
            <div class="bold">{{ $t('xjd.mdg') }}:</div> &nbsp;
            <div>{{ addressObj.addr_mdg }}</div>
        </div>
        <div class="align-start">
            <div class="bold">{{ $t('orderObj.emailOnly') }}:</div> &nbsp;
            <div>{{ addressObj.addr_postal }}</div>
        </div>
    </div>
    <div style="width:380px;">
        <div class="align-start">
            <div class="bold">{{ $t('xjd.mysysj') }}:</div> &nbsp;
            <div>{{ addressObj.addr_mysysj }}</div>
        </div>
        <div class="align-start">
            <div class="bold">{{ $t('xjd.ysfs') }}:</div> &nbsp;
            <div>{{ addressObj.addr_paytype }}</div>
        </div>
    </div>

    <div class="pointer edit1" @click="fnSelectAddress">
        {{ $t('addressObj.edit') }}
    </div>


</div>
<div class="font-005BAC-24-500 bold" style="margin:20px 0 20px;">
    <div>{{ $t('orderObj.sRequire').toUpperCase() }} <span class="xian1"></span> </div>
</div>

<textarea class="flex-con" :placeholder="$t('orderObj.sRequireXq')" v-model="order_intro"></textarea>

<div class="align-end">
    <div class="title2 align-center pointer" style="margin-top: 58px;" @click="initCommitOrder()">
        <img :src="require('@/assets/sure.png')" alt="" class="jia1">
        <div>{{ $t('carObj.tjdd') }}</div>
    </div>
</div>


</div>




        <Icon />
        <Footer />
    </div>
</template>
  
<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CateList from '@/components/CateList.vue'
import Icon from '@/components/Icon.vue'
import { showConfirmOrder, shopCarCommitOrder, showAddress, goodsCommitOrder } from '../api'
export default {
    components: {
        Header,
        Footer,
        CateList,
        Icon
    },
    data() {
        return {
            data: [],
            addressObj: {},
            goods_quantity:this.$route.query.goods_quantity,
            fromName:'',
            order_intro:''
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
        console.log(from.name);
            vm.fromName = from.name
        })
    },
    mounted() {
        this.initShowConfirmOrder()

        if (this.fromName == 'myAddress') {
            this.addressObj = JSON.parse(localStorage.getItem('wlAddressPc'));
            this.order_intro = localStorage.getItem('order_intro');
        } else {
            localStorage.setItem('wlAddressPc', '')
            this.initShowAddress()
        }
    },
    methods: {
        initCommitOrder() {
                if (!this.addressObj) {
                    this.$message.error(this.$t('carObj.qxzdz'))
                    return
                }
                goodsCommitOrder({
                    addr_id: this.addressObj.addr_id,
                    goods_id: this.$route.query.goods_id,
                    packaging_id: this.$route.query.packaging_id,
                    spec_id: this.$route.query.spec_id,
                    goods_quantity: this.goods_quantity,
                    order_remark:this.order_intro
                }).then((data) => {
                    if (data.status == 200) {
                        this.$router.push({
                            name: 'orderCenter',
                            params: {
                                id: 1
                            },
                        })
                    }
                })

        },
        initShowAddress() {
            let params = {}
            showAddress(params).then((data) => {
                if (data.status == 200) {
                    this.addressObj = data.msg
                }
            })
        },
        fnSelectAddress() {
            localStorage.setItem('order_intro', this.order_intro)
            this.$router.push({
                path: 'myAddress',
                query: {
                    from: 1
                },
            })
        },
        initShowConfirmOrder() {
            let params = {
                sc_ids: this.$route.query.sc_ids,
                goods_id: this.$route.query.goods_id,
                packaging_id: this.$route.query.packaging_id,
                spec_id: this.$route.query.spec_id,
                goods_quantity: this.$route.query.goods_quantity
            }
            showConfirmOrder(params).then((data) => {
                if (data.status == 200) {
                    this.data = data
                }
            })
        }
    }

}
</script>
  
<style scoped>
.btn2 {
    font-size: 18px;
    font-weight: 500;
    color: #FFFFFF;
    background: #018ECF;
    border-radius: 4px;
    height: 59px;
}

.btn1 {
    width: 249px;
    height: 59px;
    border-radius: 4px;
    border: 1px solid #979797;
}

input[type='checkbox'] {
    -webkit-appearance: none;
    width: 24px;
    height: 24px;
    border: 2px solid #979797;
    border-radius: 2px;
    margin-right: 16px;
}

input[type='checkbox']:checked {
    border: 2px solid #018ECF;
    background: url('@/assets/checked1.png') no-repeat center center;
    background-size: 18px 18px;
}

.box2 {
    height: 42px;
    background: #005BAC;
    line-height: 42px;
    font-weight: bold;
    font-size: 16px;
    color: #FFFFFF;
    margin-top: 32px;
    margin-bottom: 18px;
}

/* .box5 {
    padding: 10px 12px 12px;
} */

.box5 img.img1 {
    width: 78px;
    height: 70px;
    min-width: 78px;
    margin-right: 12px;
}

.order-item {
    margin-bottom: 15px;
    border: 1px solid #EEEEEE;
    padding: 24px 0 26px 20px;
}

.order-item:hover {
    background: #F8F8F8;
}

.box3 {
    padding: 10px 12px;
    border-radius: 4px;
    border: 1px solid #979797;
    min-width: 550px;
}

.four-box {
    /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); */
}

.box {
    padding: 30px 50px 60px;
    width: 1200px;
    background-color: #FFFFFF;
    box-sizing: border-box;
}

/* 加减数 */


/* 加减数 */
::v-deep .el-input-number {
    width: 100%;
    height: 42px;
    border: none;
    line-height: 42px;
}

::v-deep .el-input-number__increase {
    border-left: none;
    width: 42px;
    height: 42px;
    background: none;
    font-size: 20px;
}

::v-deep .el-input-number__decrease {
    background: none;
    border-right: none;
    width: 42px;
    height: 42px;
    font-size: 20px;
}

::v-deep .el-input-number .el-input {
    height: 42px;
}

::v-deep .el-input-number .el-input__inner {
    padding-left: 0;
    padding-right: 0;
    border: none;
    height: 42px;
    font-size: 24px;
    border: 1px solid #e5e5e5;
    width: 100%;
    line-height: 42px;
}

::v-deep .el-input__inner {
    border-radius: 21px;
    color: #003275;
    background: #fff;
}

::v-deep [class*=" el-icon-"],
::v-deep [class^=el-icon-] {
    font-family: element-icons !important;
    speak: none;
    font-style: normal;
    font-weight: bold;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    vertical-align: baseline;
    display: inline-block;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


::v-deep .el-input-number__decrease:hover,
::v-deep .el-input-number__increase:hover {
    color: #003275
}

::v-deep .el-input-number__decrease:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled),
::v-deep .el-input-number__increase:hover:not(.is-disabled)~.el-input .el-input__inner:not(.is-disabled) {
    border-color: #003275;
}

::v-deep .el-input-number__decrease.is-disabled,
::v-deep .el-input-number__increase.is-disabled {
    color: #C0C4CC;
    cursor: not-allowed
}



/* dialog */

::v-deep .el-dialog {
    border-radius: 6px;
}

::v-deep .el-button:hover {
    background: #FFF;
    border: 1px solid #DCDFE6;
    color: #606266;
}

::v-deep .el-button--primary,
.el-button--primary:hover {
    color: #FFF;
    background-color: #005BAC;
    border-color: #005BAC;
}


.yellow1 {
    padding: 14px 12px 16px 18px;
    background: #F9BE00;
    font-size: 16px;
    color: #000000;
    line-height: 26px;
    margin-top: 20px;
}

.title2 {
    width: 240px;
    height: 48px;
    background: #005BAC;
    border-radius: 24px;
    font-size: 16px;
    color: #FFFFFF;
    margin-top: 28px;
}

.jia1 {
    width: 24px;
    height: 24px;
    margin-right: 24px;
}

textarea {
    width: 100%;
    min-height: 78px;
    border: 1px solid #eee;
    padding: 15px;
    font-size: 16px;
    color: #333;
    outline: none;
    box-sizing: border-box;
    font-family: "微软雅黑";
}

textarea::-webkit-input-placeholder {
    color: #999999;
    /* font-family: "微软雅黑"; */
}

.xian2 {
    position: absolute;
    margin: auto;
    left: 11px;
    top: 12px;
    width: 6px;
    height: 18px;
    background: #F9BE00;
}

.edit1 {
    height: 25px;
    position: absolute;
    margin: auto;
    right: 37px;
    top: 0;
    bottom: 0;
    font-size: 16px;
    color: #005BAC;
}
</style>