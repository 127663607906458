<template>
  <div style="background-color:#f3f3f3;">
    <div class="font-fff-18-500 align-center h42 bg-005BAC bold">{{ $t('footAll.gywm') }}</div>
    <div class="box">
      <div class="pointer align-start" :class="{ active: num == 1 }" @click="fnOpen(1)">{{ $t('footAll.js') }}</div>
      <div class="pointer align-start" :class="{ active: num == 2 }" @click="fnOpen(2)">{{ $t('footAll.news') }}</div>
      <div class="pointer align-start" :class="{ active: num == 3 }" @click="fnOpen(3)">{{ $t('footAll.lxwm') }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    num111: {
      Type: Number,
      default: 999,
    },
  },
  data() {
    return {
      num: 1
    }
  },
  methods: {
    fnOpen(type) {
      if (type == 1) {
        this.$router.push({
          name: 'intro'
        })
      }
      if (type == 2) {
        this.$router.push({
          name: 'news'
        })
      }
      if (type == 3) {
        this.$router.push({
          name: 'connect'
        })
      }
    }
  },
  created() {

  },
  mounted() {
    this.num = this.num111
  }
}
</script>


<style scoped>
.box {
  padding: 10px;
  /* line-height: 25px; */
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  /* line-height: 48px; */
}

.box>div.pointer {
  min-height: 45px;
  border-bottom: 1px dotted #ccc;
  padding-left: 20px;
}

.box>div.pointer:last-child {
  border-bottom: none;
}

.img_icon {
  width: 26px;
  height: 26px;
  margin-right: 8px;
}

.box>div.pointer:hover,
.box>div.pointer.active {
  /* font-weight: 500; */
  color: #005BAC;
}
</style>