<template>
  <div class="bg-fff">
    <Header />
    <div class="flex-wrap" style="height: 60px;width: 1200px;margin: 30px auto 42px;">
      <CateList />

      <div class="flex-con align-end font-999-16 bg-fff1 p_r18">
        {{ $t('logon.local') }} > {{
          type == 0 ? $t('orderObj.allPurOrder') : type == 1 ? $t('headGRZXObj.dqr') : type == 2 ? $t('headGRZXObj.dfk') :
            type == 3 ? $t('headGRZXObj.dfh') : type == 4 ? $t('headGRZXObj.dsh') : $t('headGRZXObj.ywc')
        }}
      </div>

    </div>
    <div class="flex-wrap box margin">
      <div class="left1">
        <MyInfo @update="initDataShua" :num="type" />
      </div>


      <div class="flex-con four-box">
        <div class="font-005BAC-24-500 align-center bold" style="margin-bottom:25px;">
          <div>{{
            (type == 0 ? $t('orderObj.allPurOrder') : type == 1 ? $t('headGRZXObj.dqr') : type == 2 ?
              $t('headGRZXObj.dfk') : type == 3 ? $t('headGRZXObj.dfh') : type == 4 ? $t('headGRZXObj.dsh') :
                $t('headGRZXObj.ywc')).toUpperCase()
          }} <span class="xian"></span> </div>
        </div>
        <div class="align-start" style="border: 2px solid #CCCCCC; width: 624px;margin: 0 auto 40px;">
          <div class="align-start">
            <img style="width: 24px; height: 24px; margin: 0 12px" :src="require('@/assets/search1.png')" fit="cover"
              alt="" />
            <input type="text" name="" value="" v-model="keyword" :placeholder="$t('orderObj.searchP')"
              @keyup.enter="fnSearch()" />
          </div>
          <div class="search_txt align-center pointer font-fff-18-bold" @click="fnSearch()">
            {{ $t('orderObj.search') }}
          </div>
        </div>


        <div class="order_pro center">
          <div class="pro_title">{{ $t('orderObj.orderProcess') }}</div>

          <div class="pro_all align-around1" style="padding:0 20px;">
            <div class="pro_item relative" style="width:132px;">
              <div class="pro_num bg_FF6600">1</div>
              <div class="pro_title1 bg_FF6600">{{ $t('orderObj.order1') }}</div>
              <div class="pro_intro">{{ $t('orderObj.order_intro1') }}</div>
            </div>

            <div class="pro_item relative" style="width:118px;">
              <div class="pro_num bg_009982">2</div>
              <div class="pro_title1 bg_009982">{{ $t('orderObj.order2') }}</div>
              <div class="pro_intro">{{ $t('orderObj.order_intro2') }}</div>
            </div>

            <div class="pro_item relative" style="width:90px;">
              <div class="pro_num bg_009982">3</div>
              <div class="pro_title1 bg_009982">{{ $t('orderObj.order3') }}</div>
              <div class="pro_intro">{{ $t('orderObj.order_intro3') }}</div>
            </div>

            <div class="pro_item relative" style="width:132px;">
              <div class="pro_num bg_FF6600">4</div>
              <div class="pro_title1 bg_FF6600">{{ $t('orderObj.order4') }}</div>
              <div class="pro_intro">{{ $t('orderObj.order_intro4') }}</div>
            </div>

            <div class="pro_item relative" style="width:150px;">
              <div class="pro_num bg_009982">5</div>
              <div class="pro_title1 bg_009982">{{ $t('orderObj.order5') }}</div>
              <div class="pro_intro">{{ $t('orderObj.order_intro5') }}</div>
            </div>

            <div class="pro_item relative" style="width:132px;">
              <div class="pro_num bg_009982">6</div>
              <div class="pro_title1 bg_009982">{{ $t('orderObj.order6') }}</div>
              <div class="pro_intro">{{ $t('orderObj.order_intro6') }}</div>
            </div>
          </div>



        </div>


        <!-- <div class="align-between" style="border: 2px solid #005BAC;height: 48px;box-sizing: border-box;">
          <input type="text" name="" value="" :placeholder="$t('orderObj.reorder')" style="padding-left: 16px;"
            readonly />
          <img style="width: 20px; height: 20px; margin: 0 20px" :src="require('@/assets/bot_img.png')" fit="cover"
            alt="" class="pointer" />
        </div>

        <div class="filter_all" v-show="works.length > 0">
          <div class="pointer filter_item font-333-14" @click="fnFilter(item.user_id)" v-for="(item, index) in works"
            :key="item.user_id">mike@sfixf.bz {{ item.user_name || '' }}</div>
        </div> -->


        <el-select v-model="value" filterable :placeholder="$t('orderObj.reorder')" v-show="works.length > 0"
          @change="fnFilter">
          <el-option v-for="item in works" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <div class="flex-wrap align-between center box2">
          <div class="flex-con">{{ $t('orderObj.ddxx') }}</div>
          <div style="width:100px;">{{ $t('orderObj.gg') }}</div>
          <div style="width:170px;">{{ $t('carObj.je') }}</div>
          <div style="width:100px;padding-right: 10px;">{{ $t('orderObj.zt') }}</div>
          <div style="width:160px;">{{ $t('orderObj.cz') }}</div>
        </div>
        <div class="box3">
          <div class="order-item m_t26" v-for="(item, index) in data" :key="item.order_id">
            <div class="box4 align-between">
              <div class="align-start">
                <div class="orderBm">{{ $t('orderObj.orderID') }}. <span class="color-fff">{{
                  item.order_number }}</span></div>
                <div class="bold font-005BAC-14-500" style="margin-left: 32px;">{{ $t('orderObj.ddsj') }}: <span
                    class="font-666-14">{{
                      item.order_time }}</span></div>
              </div>
              <div class="pointer align-start" @click="fnDetail(item.order_id)">
                <img style="width: 20px; height: 20px; margin-right: 8px" :src="require('@/assets/look1.png')"
                  fit="cover" alt="" />
                <div class="font-005BAC-16">{{ $t('orderObj.ddxq') }}</div>
              </div>
            </div>

            <div class="order11 align-start" v-show="item.order_wuLiu_desc">
              <img style="width: 24px; height: 24px; margin-right: 6px" :src="require('@/assets/news1.png')" fit="cover"
                alt="" />
              <div class="font-999-14">{{ item.order_update_time }}</div> &nbsp;
              <div class="font-333-14">{{ item.order_wuLiu_desc || '' }} <span class="color-FF0000"
                  v-show="item.order_wuLiu_code">{{ item.order_wuLiu_code }}</span></div>
            </div>
            <div class="box6">
              <div class="box5 ">
                <div class="flex-wrap align-between center" style="border-bottom: 1px dotted #ccc;">
                  <div class="flex-con left" style="padding: 7px 0 0 3px;">
                    <div class="flex-wrap box7" style="padding:20px 0;" v-for="(item, index) in item.orderGoods"
                      :key="item.of_id">
                      <div class="flex-con align-start">
                        <img class="goods_img" :src="item.goods_thumb" alt="">
                        <div class="font-333-14 m_r8">{{ $i18n.locale == 'en' ?
                          item.goods_name_en
                          : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}</div>
                      </div>
                      <div class="font-333-16 align-center" style="min-width:130px;width:130px;">
                        {{ item.packaging_name }} <br> {{ item.spec_value }}
                      </div>
                      <div class="font-333-16 align-center" style="width:150px;">{{ $t('JC') }}
                        $ {{ item.of_price * item.of_quantity }}
                      </div>

                    </div>
                  </div>
                  <!-- <div class="font-333-16" style="width:150px;">{{ $t('JC') }}
                $ {{ item.order_total_price }}
              </div> -->
                  <div class="font-333-14 p_lr10 border-box" style="width: 136px;padding-right: 25px;">
                    <div>
                      <img style="width: 38px; height: 38px;" :src="require(item.order_status == 1 ? '@/assets/myInfo05.png' :
                        item.order_status == 2 ?
                          '@/assets/myInfo06.png' : item.order_status == 3 ? '@/assets/myInfo07.png' :
                            item.order_status == 4 ?
                              '@/assets/myInfo08.png' : item.order_status == 5 ? '@/assets/myInfo09.png' :
                                '@/assets/myInfo10.png')" fit="cover" alt="" />
                    </div>
                    {{ item.order_status == 1 ? $t('headGRZXObj.dqr') :
                      item.order_status == 2 ?
                        $t('headGRZXObj.dfk') : item.order_status == 3 ? $t('headGRZXObj.dfh') :
                          item.order_status == 4 ?
                            $t('headGRZXObj.dsh') : item.order_status == 5 ? $t('headGRZXObj.ywc') :
                              $t('headGRZXObj.yqx') }}
                  </div>
                  <div style="width:127px;" class="btnOut">
                    <div class="btn align-center pointer bg-005BAC" v-show="item.order_status == 1"
                      @click.stop="fnSure(1, item.order_id)">{{
                        $t('orderObj.qrdd') }}</div>
                    <div class="btn align-center pointer bg-989898"
                      v-show="item.order_status == 1 || item.order_status == 2" @click.stop="fnSure(2, item.order_id)">
                      {{
                        $t('orderObj.qxdd')
                      }}</div>
                    <!-- <div class="btn align-center pointer bg-005BAC" v-show="item.order_status == 2"
                      @click.stop="fnSure(2, item.order_id)">{{ $t('orderObj.qxdd')
                      }}</div> -->
                    <div class="btn align-center pointer bg-005BAC" v-show="item.order_status == 4"
                      @click.stop="fnSure(3, item.order_id)">{{
                        $t('orderObj.qrsh') }}</div>
                    <div class="btn align-center pointer bg-005BAC"
                      v-show="item.order_status == 5 && item.orderGoods.length == 1"
                      @click.stop="fnGoodsDetails(item.orderGoods[0].of_goods_id)">{{ $t('orderObj.reorder')
                      }}</div>
                  </div>
                </div>
              </div>
              <div style="height: 78px;padding: 0 18px 0 28px;" class="align-between">
                <div>
                  <div class="font-999-14">{{ $t('orderObj.goodsPrice') }}: {{ $t('JC') }} ${{
                    item.order_total_price - item.order_express_price }}, {{
                      $t('orderObj.otherExpenses') }}: {{ item.order_express_price || $t('orderObj.waitAccount') }} </div>
                  <div class="font-005BAC-16 m_t14">{{ $t('orderObj.totalAmount') }}: $ {{ item.order_total_price }}
                  </div>
                </div>
                <div class="font-646464-14-bold">{{ $t('orderObj.presenter') }}: <span class="font-005BAC-14-500">{{
                  item.user_name }} /
                    {{ item.user_email }}</span></div>
              </div>
            </div>
          </div>



          <div class="align-center m_t12">
            <Page :limit="limit" :totalNum="total" @onPage="changePage" />
          </div>
        </div>


      </div>



    </div>
    <Footer />
    <el-dialog :title="$t('carObj.tip')" :visible.sync="dialogVisible" width="30%" center :before-close="handleClose"
      append-to-body>
      <span>{{ ts }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">{{ $t('other.cancel') }}</el-button>
        <el-button type="primary" @click="submit" size="mini">{{ $t('other.confirm') }}</el-button>
      </span>
    </el-dialog>
    <Icon />
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import CateList from '@/components/CateList.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import MyInfo from '@/components/MyInfo.vue'
import Page from '@/components/Page.vue'
import { limit, orderList, sureOrder, overOrder, cancelOrder } from '../api'
export default {
  components: {
    Header,
    Footer,
    MyInfo,
    Page,
    CateList,
    Icon
  },
  data() {
    return {
      page: 1,
      limit: limit,
      total: 0,
      type: 0,
      data: [],
      ts: '',
      dialogVisible: false,
      orderId: '',
      where: 0,
      keyword: '',
      works: [],
      value: '',
      work_uid: '',
    }
  },
  mounted() {
    let can = this.$route.params.id
    this.type = can

    if (this.fromName == 'orderDetails') {
      this.keyword = localStorage.getItem('orderKeyword');
    }

    this.initOrderList()

  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      console.log(from.name);
      vm.fromName = from.name
    })
  },
  methods: {

    fnSearch() {
      this.page = 1
      this.initOrderList()
    },

    fnFilter(idd) {
      this.page = 1
      this.work_uid = idd
      this.initOrderList()
    },

    initOrderList() {
      let params = {
        status: this.type,
        page: this.page,
        keyword: this.keyword,
        work_uid: this.work_uid
      }
      orderList(params).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
  
          this.total = data.count
          let arr = []
          arr.push({
            value: '',
            label: this.$t('headGRZXObj.qbdd')
          })
          data.works.forEach((element) => {
            arr.push({
              value: element.user_id,
              label: element.user_name + ' / ' + element.user_email
            })
          })

          this.works = arr
        }
      })
    },
    fnDetail(idd) {
      this.$router.push({
        name: 'orderDetails',
        params: {
          order_id: idd,
          id: this.type
        }
      })
    },
    fnSure(type, id) {
      this.dialogVisible = true;
      this.where = type;
      if (this.where == 1) {
        this.ts = this.$t('other.sure1')
      } else if (this.where == 2) {
        this.ts = this.$t('other.sure2')
      } else {
        this.ts = this.$t('other.sure3')
      }
      this.orderId = id;
    },
    submit() {
      if (this.where == 1) {
        this.fnSureOrder(this.orderId);
      } else if (this.where == 2) {
        this.fnCancleOrder(this.orderId);
      } else {
        this.fnSureSh(this.orderId)
      }
    },
    fnSureOrder(idd) {
      let params = {
        order_id: idd
      }
      sureOrder(params).then((data) => {
        if (data.status == 200) {
          this.$message.success(data.msg)
          this.dialogVisible = false;
          this.page = 1
          this.initOrderList()
        }
      })
    },
    fnCancleOrder(idd) {
      let params = {
        order_id: idd
      }
      cancelOrder(params).then((data) => {
        if (data.status == 200) {
          this.$message.success(data.msg)
          this.dialogVisible = false;
          this.page = 1
          this.initOrderList()
        }
      })
    },
    fnSureSh(idd) {
      let params = {
        order_id: idd
      }
      overOrder(params).then((data) => {
        if (data.status == 200) {
          this.$message.success(data.msg)
          this.page = 1
          this.initOrderList()
        }
      })
    },
    initDataShua(idd) {
      // console.log(idd);
      this.type = idd
      this.page = 1
      this.initOrderList()
    },
    changePage(childrenData) {
      this.page = childrenData;
      this.initOrderList()
    },
    handleClose(done) {
      done();
    },
  }

}
</script>


<style scoped>
::v-deep .el-button--primary {
  color: #FFF;
  background-color: #005BAC;
  border-color: #005BAC;
}

::v-deep .el-button--primary:focus,
::v-deep .el-button--primary:hover {
  background-color: #005BAC;
  border-color: #005BAC;
}

::v-deep .el-button--default:hover {
  border: 1px solid #DCDFE6;
  color: #606266;
  background-color: #fff;
}

.btn {
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
  /* line-height: 28px; */
  box-sizing: border-box;
  margin-top: 8px;

  width: 118px;
  height: 28px;
  border-radius: 14px;
}

.btnOut>.btn:first-child {
  margin-top: none;
}

.box5 {
  padding: 0 15px 0 27px;
}

.box6 {
  border: 1px solid #EEEEEE;
  border-top: none;
}

.box6:hover {
  background: #EFF7FF;
}

.box5 img.goods_img {
  width: 78px;
  height: 70px;
  min-width: 78px;
  margin-right: 12px;
}

.box4 {
  /* padding: 12px 16px;
  background: #f4f4f4; */
  height: 42px;
  background: #EFF7FF;
  padding-right: 16px;
  line-height: 42px;
  border-top-left-radius: 16px;
}

.order-item {
  /* border: 1px solid #f2f2f2; */
}

.box1 {
  padding: 16px 16px 12px;
}

.box2 {
  /* padding: 0 28px 12px 16px;
  border-bottom: 1px solid #979797; */
  height: 42px;
  background: #005BAC;
  /* border: 1px solid #EEEEEE; */
  line-height: 42px;
  font-weight: bold;
  font-size: 16px;
  color: #FFFFFF;
  margin: 25px 0;
}

.box3 {
  /* padding: 0 16px 12px; */
}

.four-box {
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); */
  padding: 0 28px 0 60px;
}

.four-out {
  display: flex;
  flex-wrap: wrap;
}

.fourImg {
  display: block;
  width: 158px;
  height: 158px;
  margin: 0 auto 6px;
  transition: all 0.3s;
}

.four-out .four {
  margin-right: 12px;
  margin-top: 12px;
  position: relative;
  display: inline-block;
  width: calc((100% - 24px) / 3);
  background: #f8f8f8;
  box-sizing: border-box;
  padding: 6px 0 12px;
  transition: all 0.3s;
}

.four-out .four:hover {
  padding-top: 30px;
}

.four-out .four:nth-child(3n) {
  margin-right: 0;
}

.btn1 {
  font-size: 18px;
  font-weight: 400;
  color: #ffffff;
  line-height: 25px;
  height: 34px;
  background: #008dd1;
  border-radius: 17px;
  width: 208px;
  transition: all 0.3s;
}

.four:hover .product-intro {
  display: block;
}

.four:hover .fourImg,
.four:hover .btn1 {
  display: none;
}

.product-intro {
  height: 40%;
  /* overflow-y: scroll; */
  display: none;
  margin-top: 20px;
  padding: 0 20px;
  transition: all 0.3s;
}

.product-intro::-webkit-scrollbar {
  display: none;
}

.collect {
  display: none;
  width: 28px;
  height: 28px;
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 1;
}

.left1 {
  width: 200px;
  /* margin-right: 30px; */
}

.box {
  padding: 26px 20px;
  width: 1200px;
  box-sizing: border-box;
  background-color: #fff;
}



.search_txt {
  min-width: 108px;
  height: 44px;
  background: #00BBC2;
  font-weight: 400;
  font-size: 16px;
  color: #FFFFFF;
}

input[type='text'] {
  background: #ffffff;
  border-radius: 0;
  border: none;
  box-sizing: border-box;
  height: 44px;
  width: 468px;
  padding: 0 12px 0 0;
  color: #333333;
  font-size: 14px;
  outline: none;
}

input[type='text']::-webkit-input-placeholder {
  color: #999;
}

input[type='text']:active {
  box-sizing: border-box;
  border: none;
}


.order_pro {
  min-height: 213px;
  background: url('@/assets/order_kuang.png') no-repeat center center;
  background-size: 100% 100%;
  width: 100%;
  margin-bottom: 30px;
}

.pro_title {
  font-weight: bold;
  font-size: 18px;
  color: #666666;
  padding-top: 16px;
  /* line-height: 36px; */
}

.pro_title1 {
  font-size: 14px;
  color: #FFFFFF;
  line-height: 28px;
  border-radius: 14px;
  margin-bottom: 10px;
  margin-top: 52px
}

.pro_intro {
  font-size: 14px;
  color: #666666;
  line-height: 18px;
}

.pro_num {
  font-weight: bold;
  font-size: 14px;
  color: #FFFFFF;
  line-height: 28px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  position: absolute;
  left: 0;
  right: 0;
  top: 32px;
  margin: 0 auto;
}

.orderBm {
  font-weight: bold;
  font-size: 16px;
  color: #005BAC;
  background-color: #66CCFF;
  border-top-left-radius: 16px;
  width: 288px;
  height: 100%;
  padding-left: 26px;
  box-sizing: border-box;
}

.order11 {
  height: 42px;
  border: 1px solid #EEEEEE;
  line-height: 42px;
  padding-left: 10px;
}

.filter_all {
  line-height: 26px;
  padding: 11px 16px;
  background: #F3F3F3;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
}

.box7 {
  border-bottom: 1px dotted #ccc;
}

.left .box7:last-child {
  border-bottom: none;
}

.filter_item {
  color: #333;
  font-size: 14px;
}

.filter_item:hover {
  color: #005BAC;
}

::v-deep .el-input__inner {
  -webkit-appearance: none;
  background-color: #FFF;
  background-image: none;
  border-radius: 0;
  border: 2px solid rgb(0, 91, 172);
  box-sizing: border-box;
  color: #333;
  display: inline-block;
  height: 48px;
  line-height: 48px;
  outline: 0;
  padding: 0 15px;
  transition: border-color .2s cubic-bezier(.645, .045, .355, 1);
  width: 100%;
}

::v-deep .el-select {
  display: inline-block;
  position: relative;
  width: 100%;
}

::v-deep .el-select:hover .el-input__inner {
  border-color: rgb(0, 91, 172)
}

::v-deep .el-select-dropdown__item {
  color: #333;
  font-size: 12px;
}


::v-deep .el-select-dropdown__item.selected {
  color: #005BAC;
  font-weight: bold;
}

::v-deep .el-icon-arrow-up:before {
  content: "";
  display: inline-block;
  width: 10px;
  /* 设置图片宽度 */
  height: 10px;
  /* 设置图片高度 */
  background-image: url('@/assets/bot_img.png');
  /* 图片路径 */
  background-size: cover;
  /* 背景图片覆盖整个元素 */
  background-position: center;
  /* 背景图片居中 */
}

/* ::v-deep .el-select-dropdown__item.selected {
  color: #005BAC;
  font-weight: 700
} */

/* ::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
  color: #005BAC;
  background-color: #FFF
}
::v-deep .el-select-dropdown.is-multiple .el-select-dropdown__item.selected {
    color: #005BAC;
    background-color: #FFF
} */

::v-deep .el-select .el-input.is-focus .el-input__inner {
  border-color: #005BAC
}
</style>