<template>
  <div style="background-color: #fff; ">
    <div class="head_contain">
      <div style="width: 100%;height: 36px;background-color: #005BAC;line-height: 36px;">
        <div class="head_tab">
          <div class="flex-wrap" style="height: 36px;">

            <div class="align-center">
              <img :src="require('@/assets/home/email.png')" style="width: 20px;height:20px;margin-right: 8px;"
                fit="cover" alt="" />
              <div class="tab2_item">info@molmaterial.com</div>
            </div>
            <div class="flex-con"></div>
            <!-- <div class="align-center pointer">
              <div class="tab2_item" @click="fnXjd()">
                {{ $t('headXJD') }}
              </div>
              <div class="hong" v-show="xjdNum > 0"></div>
            </div>
            <div class="s_xian">|</div> -->

            <div class="align-center pointer" @click="goCollect()" v-show="user_id">
              <img :src="require('@/assets/home/collect.png')" style="width: 20px;height:20px;margin-right: 4px;"
                fit="cover" alt="" />
              <div class="tab2_item">{{ $t('headGRZXObj.wdsc') }}</div>
            </div>
            <div class="align-center pointer" @click="toLogin()" v-show="!user_id">
              <img :src="require('@/assets/home/collect.png')" style="width: 20px;height:20px;margin-right: 4px;"
                fit="cover" alt="" />
              <div class="tab2_item">{{ $t('logon.dl') }}</div>
            </div>
            <div class="s_xian">|</div>


            <div class="align-center pointer" @click="toRegister()" v-show="!user_id">
              <img :src="require('@/assets/home/user.png')" style="width: 20px;height:20px;margin-right: 4px;"
                fit="cover" alt="" />
              <div class="tab2_item">{{ $t('logon.register') }}</div>
            </div>

            <div class="relative dropdown_out" v-show="user_id">
              <div class="align-center" style="height: 100%;">
                <img :src="require('@/assets/home/user.png')" style="width: 20px;height:20px;margin-right: 4px;"
                  fit="cover" alt="" />
                <div class="tab2_item">{{ user_name }}</div>
                <img :src="require('@/assets/home/jt_b.png')" style="width: 20px;height:20px;margin-left: 4px;"
                  fit="cover" alt="" />
              </div>
              <div class="my_info">
                <div class="align-start pointer" @click="handleCommand1('jbxx')">
                  <img :src="require('@/assets/home/myInfo01.png')" fit="cover" alt="" /> {{ $t('headGRZXObj.jbxx')
                  }}
                </div>
                <div class="align-start pointer" @click="handleCommand1('dzgl')">
                  <img :src="require('@/assets/home/myInfo02.png')" fit="cover" alt="" /> {{ $t('headGRZXObj.dzgl')
                  }}
                </div>
                <div class="align-start pointer" @click="handleCommand1('wdsc')">
                  <img :src="require('@/assets/home/myInfo03.png')" fit="cover" alt="" /> {{ $t('headGRZXObj.wdsc')
                  }}
                </div>

                <div class="b-b-fff" style="margin:15px 0 11px;"></div>
                <div class="align-start pointer" @click="handleCommand1(0)">
                  <img :src="require('@/assets/home/myInfo04.png')" fit="cover" alt="" /> {{ $t('headGRZXObj.qbdd')
                  }}
                </div>
                <div class="align-start pointer" @click="handleCommand1(1)">
                  <img :src="require('@/assets/home/myInfo05.png')" fit="cover" alt="" /> {{ $t('headGRZXObj.dqr')
                  }}
                </div>
                <div class="align-start pointer" @click="handleCommand1(2)">
                  <img :src="require('@/assets/home/myInfo06.png')" fit="cover" alt="" /> {{ $t('headGRZXObj.dfk')
                  }}
                </div>
                <div class="align-start pointer" @click="handleCommand1(3)">
                  <img :src="require('@/assets/home/myInfo07.png')" fit="cover" alt="" /> {{ $t('headGRZXObj.dfh')
                  }}
                </div>
                <div class="align-start pointer" @click="handleCommand1(4)">
                  <img :src="require('@/assets/home/myInfo08.png')" fit="cover" alt="" /> {{ $t('headGRZXObj.dsh')
                  }}
                </div>
                <div class="align-start pointer" @click="handleCommand1(5)">
                  <img :src="require('@/assets/home/myInfo09.png')" fit="cover" alt="" /> {{ $t('headGRZXObj.ywc')
                  }}
                </div>
                <div class="b-b-fff" style="margin:23px 0 11px;"></div>
                <div class="align-start pointer" @click="handleCommand1('tczh')">
                  <img :src="require('@/assets/home/myInfo10.png')" fit="cover" alt="" /> {{ $t('headGRZXObj.tczh')
                  }}
                </div>
              </div>
            </div>


            <div class="s_xian" style="margin-left:6px;">|</div>

            <div class="align-center pointer" @click="help()">
              <img :src="require('@/assets/home/help.png')" style="width: 20px;height:20px;margin-right: 4px;"
                fit="cover" alt="" />
              <div class="tab2_item">{{ $t('home.help') }}</div>
            </div>


            <div class="s_xian">|</div>


            <div class="relative dropdown_out">
              <div class="align-center" style="height: 100%;">
                <div class="tab2_item">{{ language }}</div>
                <img :src="require('@/assets/home/jt_b.png')" style="width: 20px;height:20px;margin-left: 4px;"
                  fit="cover" alt="" />
              </div>
              <div class="my_info" style="padding-left: 25px;">
                <div class="align-start pointer" @click="handleCommand('en')"> English </div>
                <div class="align-start pointer" @click="handleCommand('es')"> Español </div>
                <div class="align-start pointer" @click="handleCommand('tp')"> Português </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <div class="head_top_out b-b-eee">
        <div class="head_top flex-wrap align-start">
          <div @click="toMain()" class="pointer">
            <img :src="require('@/assets/home/logo.png')" fit="cover" class="logo" alt="" />
            <div class="font-000-16 center">{{ $t('home.shop') }}</div>
          </div>
          <div class="s_xian2"></div>

          <div class="center" style="margin-right: 50px;">
            <div class="font-000-16 bold">{{ $t('home.platform') }}</div>
            <div class="font-ffbb00-16">{{ $t('home.safe').toUpperCase() }}</div>
          </div>
          <div class="flex-con"> </div>
          <div class="align-center" style="border: 2px solid #005BAC;">
            <img :src="require('@/assets/home/search.png')" fit="cover" alt=""
              style="width: 24px;height: 24px;margin: 0 8px 0 12px;" />
            <input type="text" name="" value="" v-model="keyword" :placeholder="$t('home.place')" />
            <div class="align-center font-fff-16 bg-005BAC pointer" style="width: 108px;height: 44px;"
              @click="fnToCate()">{{ $t('xjd.ss') }}</div>
          </div>

        </div>
      </div>

    </div>

  </div>
</template>

<script>
import { alreadyReadNumber } from "../api";
export default {
  data() {
    return {
      lan: localStorage.getItem('lang') || 'en',
      language: '',
      keyword: "",
      user_name: localStorage.getItem("allUserMr") ? JSON.parse(localStorage.getItem("allUserMr")).user_name : '',
      user_id: localStorage.getItem("allUserMr") ? JSON.parse(localStorage.getItem("allUserMr")).user_id : 0,
      xjdNum: 0
    };
  },
  methods: {
    fnToCate() {
      if (this.$router.history.current.name == 'cate') {
        this.$bus.$emit('keyword', this.keyword)
      } else {
        this.$router.push({
          path: 'cate',
          query: {
            keyword: this.keyword
          }
        })
      }
    },
    goCollect() {
      this.$router.push({
        name: 'collect'
      })

    },
    help() {
      this.$router.push({ name: 'buy' })
    },
    // initCateAlreadyRead() {
    //   let params = {}
    //   if (this.user_id) {
    //     alreadyReadNumber(params).then((data) => {
    //       if (data.status == 200) {
    //         this.xjdNum = data.msg
    //       }
    //     })
    //   }
    // },
    exit() {
      localStorage.setItem('allUserMr', '')
      this.$router.push({ name: 'login' })
    },

    toRegister() {
      this.$router.push({ name: 'register' })
    },

    toLogin() {
      this.$router.push({ name: 'login' })
    },
    handleCommand(command) {
      this.language = command == 'en' ? 'English' : command == 'es' ? 'Español' : 'Português';
      this.lan = command;
      this.$i18n.locale = command;
      localStorage.setItem('lang', command)
    },
    handleCommand1(command) {
      switch (command) {
        case 'jbxx':
          this.fnCenter()
          break;
        case 'dzgl':
          this.$router.push({
            name: 'myAddress'
          })
          break;
        case 'wdsc':
          this.$router.push({
            name: 'collect'
          })
          break;
        case 'tczh':
          this.exit()
          break;
        default:
          this.$router.push({
            name: 'orderCenter',
            params: {
              id: command
            },
          })
          this.$bus.$emit("orderType", command);
          break;
      }
    },
    fnCar() {
      this.$router.push({
        name: 'car'
      })
    },
    fnCenter() {
      this.$router.push({
        name: 'myInfo'
      })
    },
    // fnXjd() {
    //   if (this.user_id) {
    //     this.$router.push({
    //       name: 'inquiry'
    //     })
    //   } else {
    //     this.toLogin();
    //   }

    // },
  },
  created() {
    this.$bus.$off('keywordGet')
    this.$bus.$off('userName')
    // this.$bus.$off('xjd')
    this.$bus.$on('keywordGet', (val) => {
      this.keyword = val || ''
    })
    this.$bus.$on('userName', (val) => {
      this.user_name = val || ''
    })
    // this.$bus.$on('xjd', () => {
    //   this.initCateAlreadyRead()
    // })
  },
  mounted() {
    // this.initCateAlreadyRead()
    this.language = this.lan == 'en' ? 'English' : this.lan == 'es' ? 'Español' : 'Português';
  },
};
</script>

<style scoped>
.hong {
  width: 6px;
  height: 6px;
  background: #FF0000;
  border-radius: 50%;
  position: relative;
  right: -2px;
  top: -9px;
}


.head_top_out {
  background-color: #fff;
}

.head_top {
  height: 98px;
  width: 1200px;
  margin: auto;
}

/* .head_top>div,
.head_top>img {
  margin-left: 38px;
} */

.head_top .logo {
  width: 136px;
  height: 28px;
  /* margin-bottom: 10px; */
}

/* ///////分割////// */
/* .item_hover_left::-webkit-scrollbar {
  display: none;
} */

.item_hover_left {
  max-width: 520px;
  overflow-y: scroll;
  padding: 20px 0;
}

/* .left1 {
  margin-top: 12px;
} */

.item_hover {
  height: 400px;
  width: 520px;
  background: #fff;
  box-sizing: border-box;
  position: absolute;
  left: 276px;
  top: 0;
  padding: 20px 40px;
  z-index: 99;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2); */
}

.tab1_menu_out {
  position: absolute;
  top: 60px;
  left: 0;
  width: 276px;
  height: 400px;
  display: none;
}

.allHover:hover .tab1_menu_out {
  display: block;
}

.tab1_menu {
  /* position: absolute;
  top: 0px;
  left: 0; */
  width: 276px;
  height: 400px;
  background: #F3F3F3;
  box-sizing: border-box;
  padding: 14px 7px 28px 10px;
  z-index: 99;
  overflow-y: scroll;
  /* box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2); */
}

/* .tab1_menu::-webkit-scrollbar {
  display: none;
} */

.tab1_menu::-webkit-scrollbar,
.item_hover_left::-webkit-scrollbar {
  width: 3px;
  border-radius: 2px;
}

.tab1_menu::-webkit-scrollbar-button,
.item_hover_left::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
  display: none;
}

.tab1_menu::-webkit-scrollbar-corner,
.item_hover_left::-webkit-scrollbar-corner {
  background-color: transparent;
}

.tab1_menu::-webkit-scrollbar-thumb,
.item_hover_left::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: rgba(0, 141, 209, .8);
}

.menu_item {
  padding-left: 8px;
  border-bottom: 1px dotted #ccc;
  height: 60px;
}

.cate1_txt {
  font-size: 18px;
  color: #333;
  margin-bottom: 8px;
}

.cate1_txt:hover {
  color: #005BAC;
}

.cate2_txt {
  font-size: 12px;
  color: #333;
}

.cate2_txt:hover {
  color: #317EE7;
}

/* .menu_item:hover {
  font-weight: 500;
  color: #2A76A8;
  background: rgba(42, 118, 168, 0.14);
} */

.second_txt {
  font-size: 16px;
  color: #333333;
  margin-bottom: 10px;
  display: inline-block;
  /* line-height: 25px; */
  /* 
  width: 49%; */
}

.second_txt:hover {
  color: #317EE7;
}

/* .item_hover_left .left1:first-child {
  margin-top: 0;
} */

.third_txt {
  font-size: 16px;
  font-weight: 400;
  color: #666666;
  line-height: 32px;
  display: inline-block;
  margin-left: 20px;
}

.third_txt:hover {
  font-weight: 500;
  color: #008DD1;
}

.font_small1 {
  flex-wrap: wrap;
}

.font_small1 div {
  font-weight: 400;
  font-size: 16px;
  color: #666;
  /* float: left; */
  margin-top: 8px;
  line-height: 22px;
  width: calc(100%/4);
}

.head_contain {
  width: 100%;
  /* min-height: 110px; */
  font-size: 16px;
  line-height: 22px;
  color: #008DD1;
  background-color: #ffffff;
  /* margin-bottom: 30px; */
}



.blue {
  color: #fff;
  margin-left: 30px;
}


.shop_num {
  min-width: 18px;
  height: 18px;
  padding: 0 4px;
  background: #018ECF;
  border-radius: 9px;
  box-sizing: border-box;
  color: #fff;
  position: relative;
  top: -12px;
  left: -2px;
}

input[type="text"] {
  background: #ffffff;
  border-radius: 0;
  border: none;
  box-sizing: border-box;
  color: #333333;
  font-size: 14px;
  outline: none;

  height: 44px;
  width: 488px;
}

input[type="text"]::-webkit-input-placeholder {
  color: #999;
}

input[type="text"]:active {
  box-sizing: border-box;
  border: none;
}



.search_icon {
  width: 20px;
  height: 20px;


  margin-top: 10px;
}



.head_tab {
  /* height: 42px; */
  background: #005BAC;
  width: 1200px;
  margin: auto;
  color: #fff;
  font-size: 14px;
  font-weight: normal;
}

.tab1 {}

.tab2 {
  margin-right: 40px;
  color: #FFFFFF;
}

.order_step {
  margin: auto;
  width: 41px;
  height: 41px;
  font-weight: 500;
  color: #aaaaaa;
  background: #ffffff;
  border: 2px solid #d5d5d5;
  font-size: 18px;
  box-sizing: border-box;
  border-radius: 50%;
  position: relative;
  z-index: 1;
}

.order_step.active {
  color: #ffffff;
  background: #c52b33;
  border: none;
}

.step_xian {
  width: 100%;
  height: 7px;
  background: #d5d5d5;
  position: absolute;
  top: 17px;
  left: -50%;
}

.step_xian.active {
  background: #c52b33;
}

.step_out {
  position: relative;
  width: 100%;
}

.gjz {
  display: block;
  text-decoration: none;
  /* width: 115px;
  height: 32px;
  background: linear-gradient(90deg, ##9B9A95 0%, #dca01e 100%) ;
  border-radius: 4px;
  */
  /* margin-left: 16px;
  text-align: center;
  line-height:32px; */
  font-size: 12px;
  /* position: absolute;
  right: 20px;
  top: 13px; */
  color: #fff;
  margin-right: 10px;
  margin-bottom: 5px;
}

.dp {
  display: block;
  text-decoration: none;
  font-size: 12px;
  /* position: absolute;
  right: 25px;
  top: 50px; */
  color: #fff;
  margin-right: 10px;
}

.head_bg {
  background: #fff;
}

.linee {
  width: 100%;
  height: 1px;
  background-color: #008DD1;
  margin-top: 16px;
}



.s_xian {
  color: #72B5F1;
  font-size: 14px;
  margin: 0 22px;
}

.el-dropdown-menu.el-popper {
  display: block;
}

.el-dropdown-menu {
  position: absolute;
  top: -10px;
  left: 0;
  z-index: 10;
  padding: 10px 0;
  margin: 0;
  background-color: #005BAC;
  border: none;
  border-radius: 0;
  box-shadow: none;
}

.el-dropdown {
  font-size: 14px;
  position: relative;
}

.el-dropdown-link {
  cursor: pointer;
  color: #fff;
}

.my_info {
  position: absolute;
  top: 36px;
  left: -24px;
  color: #fff;
  outline: 0;
  background: #005BAC;
  opacity: 0.9;
  min-width: 206px;
  padding: 13px 12px;
  z-index: 2;
  box-sizing: border-box;
  display: none;
}
.my_info div:not(.b-b-fff){
  min-height: 36px;
  line-height: 18px;
  padding: 5px 0;
  box-sizing: border-box;
}

.my_info img {
  width: 26px;
  height: 26px;
  margin: 0 8px;
}

.my_info>div:hover {
  color: #84C5FF;
}

.dropdown_out:hover .my_info {
  display: block;
}

.s_xian2 {
  width: 1px;
  height: 35px;
  background-color: #EEEEEE;
  margin: 0 18px 0 32px;
}
</style>
