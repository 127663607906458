<template>
  <div class="bg-fff">
    <Header/>
    <div class="flex-wrap" style="height: 60px;width: 1200px;margin: 30px auto 42px;">
      <CateList />

      <div class="flex-con align-end font-999-16 bg-fff1 p_r18">
        {{ $t('logon.local') }} > {{ $t('footAll.news') }}
      </div>

    </div>
    <div class="flex-wrap box11 margin">
      <div class="left1">
        <AboutUs :num111="2" />
      </div>
      <div class="box flex-con">
        <div class="font-005BAC-24-500 align-center bold" style="margin-bottom:14px;">
          <div>

            {{ ($i18n.locale == 'en' ? dataIn.n_title_en
                        : $i18n.locale == 'es' ? dataIn.n_title_es : dataIn.n_title_tp).toUpperCase() }}
  
            <span class="xian"></span>
          </div>
        </div>
        <div class="font-A2A2A2-18 center" style="margin: 30px 0;">{{dataIn.n_time}}</div>
        <div style="padding: 0 35px 0 110px;">
          <div style="padding:40px 0 50px;border-top: 1px dotted #ccc;border-bottom: 1px dotted #ccc;margin-bottom: 22px;" v-html="$t('content')">
            
          </div>

          <div class="align-start m_t18" style="font-size:18px;" v-if="data.prevId">
            <div class="nextNews font-333-18">{{ $t('new.prev') }}</div>
            <div class="pointer color-999 n_title" @click="initData(data.prevId.n_id)">
              {{ $i18n.locale == 'en' ? data.prevId.n_title_en
                        : $i18n.locale == 'es' ? data.prevId.n_title_es :  data.prevId.n_title_tp }}
</div>
          </div>
          <div class="align-start m_t18" style="font-size:18px;" v-if="data.nextId">
            <div class="nextNews font-333-18">{{ $t('new.next') }}</div>
            <div class="pointer color-999 n_title" @click="initData(data.nextId.n_id)">
              {{ $i18n.locale == 'en' ? data.nextId.n_title_en
                        : $i18n.locale == 'es' ? data.nextId.n_title_es :  data.nextId.n_title_tp }}
              </div>
          </div>



        </div>
        <!-- <div class="title">{{ type == 1 ? $t('footAll.sjbhzc') : type == 2 ? $t('footAll.yszc') : type == 3
          ? $t('footAll.gsjs') : type == 4 ? $t('footAll.lxwm') : type == 7 ? $t('headGYWM') : '' }}</div>
        <div v-html="$t('content')">
        </div> -->
      </div>
    </div>
    <Footer />
    <Icon />
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import CateList from '@/components/CateList.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import AboutUs from '@/components/AboutUs.vue'
import { newInfo } from '../api'
export default {
  components: {
    Header,
    Footer,
    AboutUs,
    CateList,
    Icon
  },
  data() {
    return {
      n_id: this.$route.params.id,
      data:{},
      dataIn:{}
    }
  },
  methods: {
    initData(idd) {
      newInfo({
        n_id: idd||this.n_id
      }).then((data) => {
        if (data.status == 200) {
          this.data=data.data
          this.dataIn=data.data.data


          this.$i18n.mergeLocaleMessage('en', { content: this.dataIn.n_content_en })
          this.$i18n.mergeLocaleMessage('es', { content: this.dataIn.n_content_es })
          this.$i18n.mergeLocaleMessage('tp', { content: this.dataIn.n_content_tp })

        }
      })
      window.scrollTo(0,0)
    },
  },
  created() {
  },
  mounted() {
    this.initData()
  },

}
</script>


<style scoped>
.bread {
  height: 45px;
  background: #f8f8f8;
  padding: 0 60px;
}

.box {
  background: #fff;
  /* padding: 0 35px 0 110px; */
  box-sizing: border-box;
}

.box11 {
  padding: 26px 20px 110px 20px;
  width: 1200px;
  background-color: #fff;
  box-sizing: border-box;
}

.title {
  font-size: 24px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 15px;
  box-sizing: border-box;
  text-align: center;
}

.left1 {
  width: 200px;
  /* margin-right: 30px; */
}



.nextNews {
  width: 80px;
  border-right: 1px solid #D2D2D2;
  margin-right: 16px;

}

.n_title:hover{
  color: #005bac;
}
</style>