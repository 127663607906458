<template>
  <div>
    <Header />
    <div class="w1200 m_b60">
      <img :src="require('@/assets/back.png')" alt="" class="back pointer" @click="fnBack">
      <div class="flex-wrap m_b30 font-333-16">
        <div class="flex-con m_r20">
          <div class="font-666-16 m_b18">{{ $t('xjd.xjdbh') }}</div>
          <div class="box1 align-start">{{ data.order_number || '' }}</div>
        </div>
        <div class="flex-con">
          <div class="font-666-16 m_b18">{{ $t('xjd.cggsmc') }}</div>
          <div class="box1 align-start" style="width: 95%;">{{ data.order_xjd_gs_name || '' }}</div>
        </div>
        <div class="flex-con">
          <div class="font-666-16 m_b18">{{ $t('xjd.cggsdz') }}</div>
          <div class="box1 align-start" >{{ data.order_xjd_gs_address || '' }}</div>
        </div>
      </div>
      <div class="flex-wrap m_b30 font-333-16">
        <div class="flex-con m_r20">
          <div class="font-666-16 m_b18">{{ $t('xjd.cgyxm') }}</div>
          <div class="box1 align-start">{{ data.order_xjd_user_name || '' }}</div>
        </div>
        <div class="flex-con">
          <div class="font-666-16 m_b18">{{ $t('xjd.cgydh') }}</div>
          <div class="box1 align-start" style="width: 95%;">{{ data.order_xjd_user_phone || '' }}</div>
        </div>
        <div class="flex-con">
          <div class="font-666-16 m_b18">{{ $t('xjd.yjdz') }}</div>
          <div class="box1 align-start" >{{ data.order_xjd_user_email || '' }}</div>
        </div>
      </div>
      <div class="flex-wrap m_b30 font-333-16">
        <div class="flex-con m_r20">
          <div class="font-666-16 m_b18">{{ $t('xjd.cyg') }}</div>
          <div class="box1 align-start">{{ data.order_xjd_cyg || '' }}</div>
        </div>
        <div class="flex-con">
          <div class="font-666-16 m_b18">{{ $t('xjd.mdg') }}</div>
          <div class="box1 align-start" style="width: 95%;">{{ data.order_xjd_mdg || '' }}</div>
        </div>
        <div class="flex-con">
          <div class="font-666-16 m_b18">{{ $t('xjd.myg') }}</div>
          <div class="box1 align-start" >{{ data.order_xjd_gettime || '' }}</div>
        </div>
      </div>
      <div class="flex-wrap m_b30 font-333-16">
        <div class="flex-con m_r20">
          <div class="font-666-16 m_b18">{{ $t('xjd.mysysj') }}</div>
          <div class="box1 align-start" >{{ data.order_xjd_mysysj || '' }}</div>
        </div>
        <div class="flex-con" >
          <div class="font-666-16 m_b18">{{ $t('xjd.ysfs') }}</div>
          <div class="box1 align-start" >{{ data.order_xjd_paytype || '' }}</div>
        </div>
        
       
      </div>
      <div class="flex-wrap m_b30 font-333-16" v-for="(item, index) in data.orderGoods" :key="index">
        <div class="flex-con m_r20">
          <div class="font-666-16 m_b18">{{ $t('xjd.cp') }}{{index+1}}</div>
          <div class="box1 align-start order"> {{ $i18n.locale == 'en' ? item.goods_name_en
      : $i18n.locale == 'es' ? item.goods_name_es
        : item.goods_name_tp }}</div>
        </div>
        <div class="flex-con">
          <div class="font-666-16 m_b18">{{ $t('orderObj.gg') }}</div>
          <div class="box1 align-start" style="width: 95%;">{{ item.spec_value || '' }}</div>
        </div>
        <div class="flex-con">
          <div class="font-666-16 m_b18">{{ $t('goodsObj.bz') }}</div>
          <div class="box1 align-start" style="width: 95%;">{{ item.packaging_name || '' }}</div>
        </div>
        <div class="flex-con">
          <div class="font-666-16 m_b18">{{ $t('goodsObj.sl') }}</div>
          <div class="box1 align-start" style="width: 95%;">{{ item.of_quantity || '' }}</div>
        </div>
        <div class="flex-con">
          <div class="font-666-16 m_b18">{{ $t('xjd.dw') }}</div>
          <div class="box1 align-start" style="width: 95%;">{{ item.of_danwei || '' }}</div>
        </div>
        <div class="flex-con">
          <div class="font-666-16 m_b18">{{ $t('xjd.xzhb') }}</div>
          <div class="box1 align-start" style="width: 95%;">{{ item.of_moneytyoe || '' }}</div>
        </div>
        <div class="flex-con" v-if="data.order_total_price>0 ">
          <div class="font-666-16 m_b18">{{ $t('xjd.dj') }}</div>
          <div class="box1 align-start" style="width: 95%;">{{ item.of_price || '' }}</div>
        </div>
        <div class="flex-con" v-if="data.order_total_price>0 ">
          <div class="font-666-16 m_b18">{{ $t('xjd.zongjia') }}</div>
          <div class="box1 align-start" style="width: 95%;">{{ item.of_total_price || '' }}</div>
        </div>
        
      </div>
     
      <div class="font-666-16 m_b18">{{ $t('xjd.pthf') }}</div>
      <div class="box2 font-333-16">{{ data.order_xjd_huifu || '' }}</div>
      <div class="pointer btn align-center" @click="createOrder()" v-if="data.order_xjd_status===2 && data.order_total_price>0 ">{{ $t('xjd.scdd') }}</div>
      <div class="pointer btn2 align-center" v-else>{{ $t('xjd.scdd') }}</div>
    </div>
    <Footer />
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import { creatOrder, alreadyRead, orderDetail } from '../api'
export default {
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      data: {}
    }
  },
  
  methods: {
    initData() {
      orderDetail({
        order_id: this.$route.params.id
      }).then((data) => {
        if (data.status == 200) {
          this.data = data.msg
          console.log(data.msg);
          console.log(888);
          
        }
      })
    },
    initRead() {
      alreadyRead({
        order_id: this.$route.params.id
      }).then((data) => {
        if (data.status == 200) {
          this.$bus.$emit('xjd')
        }
      })
    },
    fnBack() {
      this.$router.go(-1)
    },
    createOrder(){
      creatOrder({
        order_id: this.$route.params.id
      }).then((data) => {
        if (data.status == 200) {
          this.$message.success(data.msg)
          location.reload();
        }
      })
    },
  },
  mounted() {
    this.initData()
  },

}
</script>


<style scoped>
.back {
  width: 28px;
  height: 28px;
  display: block;
  margin: 20px 0 40px;
}
.btn {
  width: 300px;
  height: 52px;
  background: #018ecf;
  border-radius: 4px;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  margin: 40px 0 60px auto;
}
.btn2{
  width: 300px;
  height: 52px;
  background: #aaacad;
  border-radius: 4px;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
  margin: 40px 0 60px auto;
}
.bread {
  height: 45px;
  background: #f8f8f8;
  padding: 0 60px;
}

.box1 {
  height: 60px;
  background: #FFFFFF;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 0 0 12px;
 
}

.box2 {
  min-height: 208px;
  background: #FFFFFF;
  border-radius: 4px;
  padding: 19px 12px;
}
.order{
  overflow:hidden;
   text-overflow:ellipsis; 
  box-sizing: border-box;
  width: 105%;
}
</style>