<template>
  <div>
    <Header />
    <!-- <div class="bread align-start font-333-22-500">
      <div v-show="fCate" style="font-size: 16px;" :class="{ 'color-2A76A8': !sCate }">{{ fCate_name }}</div>
      <img v-show="sCate" :src="require('@/assets/swipe_jt_r2.png')" alt="" style="width:22px;height:22px;margin:0 20px;">
      <div v-show="sCate" style="font-size: 16px;" :class="{ 'color-2A76A8': !tCate }">{{ sCate_name }}</div>
      <img v-show="tCate" :src="require('@/assets/swipe_jt_r2.png')" alt="" style="width:22px;height:22px;margin:0 20px;">
      <div v-show="tCate" class="color-2A76A8">{{ tCate_name }}</div>
    </div> -->
    <div class="flex-wrap" style="height: 60px;width: 1200px;margin: 30px auto 42px;">
      <CateList />

      <div class="flex-con align-end font-999-16 bg-fff1 p_r18">
        {{ $t('logon.local') }} > {{ fCate_name }} <div v-show="sCate">&nbsp;> {{ sCate_name }} </div>
      </div>

    </div>
    <div class="box">
      <div>
        <!-- {{ sCate?sCate_name:fCate_name }} -->
        <div class="font-005BAC-24-500 align-center bold" v-show="fCate_name" style="margin-bottom:36px;">
          <div> {{ fCate_name }} {{  sCate_name}}  <span class="xian"></span> </div>
          <!-- <div class="font-999-18-500">edit</div> -->
        </div>
        <div class="font-666-16 align-end m_b45">
          <div class="font-333-18-500 bold">{{ $t('cateObj.sx') }}:</div>
          <div class="bold align-end" v-for="(item, index) in goodsAttrObj"
            :key="'goodsAttr_' + item.attr_id + '_' + index">
            <input type="checkbox" class="m_r10 m_l16" name="goodsAttr" :data-idd="item.attr_id"
              @click="fnSelectAttr(item.attr_id)" />
            <div>{{ $i18n.locale == 'en' ? item.attr_name_en
              : $i18n.locale == 'es' ? item.attr_name_es : item.attr_name_tp }}</div>
          </div>
        </div>
        <div class="four-out">
          <div class="four pointer relative" @click="fnGoodsDetails(item.goods_id)" v-for="(item, index) in goods"
            :key="'goods_' + item.goods_id">
            <div class="f_tou flex-wrap">
              <div class="font-333-16 flex-con" style="line-height: 36px;background: #FFC800;">
                <div class="font-333-16-500" style="padding-left: 12px;line-height: 36px;" v-show="user_id">
                  {{ $t('JC') }}
                  <!-- <span class="through color-999 normal">$ {{ item.goods_price || '' }}</span> -->
                  $ {{ item.goods_cu_price || '' }}
                </div>
              </div>
              <div class="f_collect align-center"><el-image fit="cover" :src="require(item.isCollect==1?'@/assets/star1.png':'@/assets/star2.png')"
                  class="f_collect_img"></el-image>
              </div>
            </div>
            <div class="hover_all">
              <div class="fourImg_out">
                <div class="relative">
                  <el-image fit="cover" :src="item.goods_thumb" class="fourImg"></el-image>
                  <div class="font-fff-16 product-intro align-center1 center">
                    {{ $i18n.locale == 'en' ? item.goods_intro_en
                      : $i18n.locale == 'es' ? item.goods_intro_es : item.goods_intro_tp }}
                  </div>
                </div>
              </div>
              <div class="aui-ellipsis-1 goods_name">
                {{ $i18n.locale == 'en' ? item.goods_name_en
                  : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}
              </div>
            </div>
          </div>
        </div>


        <div class="align-center m_t60" v-show="total > 0">
          <Page :limit="limit" :totalNum="total" @onPage="changePage" />
        </div>
      </div>
    </div>
    <Footer />
    <Icon />
  </div>
</template>

<script>
import Icon from '@/components/Icon.vue'
import CateList from '@/components/CateList.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Page from '@/components/Page.vue'
import { limit, goodsAttr, goodsList, goodsCateList } from '../api'
export default {
  components: {
    Header,
    Footer,
    Page,
    CateList,
    Icon
  },
  data() {
    return {
      page: 1,
      limit: limit,
      total: 0,
      goodsCate: [],
      goodsAttrObj: [],
      fCate: '',
      sCate: '',
      tCate: '',
      attr: '',
      fCate_name: '',
      sCate_name: '',
      tCate_name: '',
      goods: [],
      keyword: this.$route.query.keyword,
      user_id: localStorage.getItem("allUserMr")?JSON.parse(localStorage.getItem("allUserMr")).user_id:0,
    }
  },
  created() {
    this.$bus.$off('keyword')
    this.$bus.$off('cate')
    this.$bus.$on('keyword', (val) => {
      this.keyword = val
      this.page = 1
      this.$router.push({
        path: 'cate',
        query: {
          keyword: this.keyword
        }
      })
      this.initGoodsList()
    })
    this.$bus.$on('cate', (val) => {
      this.fCate = val.id1 || ''
      this.sCate = val.id2 || ''
      // this.tCate = val.id3 || ''
      this.fCate_name = val.id1_name || ''
      this.sCate_name = val.id2_name || ''
      // this.tCate_name = val.id3_name || ''
      this.page = 1
      this.initGoodsList()
    })
  },
  mounted() {
    this.keyword = this.$route.query.keyword || ''
    if (this.keyword) {
      this.$bus.$emit('keywordGet', this.keyword)
    }
    this.fCate = this.$route.params.id1 || ''
    this.sCate = this.$route.params.id2 || ''
    // this.tCate = this.$route.params.id3 || ''
    this.fCate_name = this.$route.params.id1_name || ''
    this.sCate_name = this.$route.params.id2_name || ''
    // this.tCate_name = this.$route.params.id3_name || ''
    // this.initCate()
    this.initGoodsAttr();
    this.initGoodsList();

  },
  methods: {
    fnSelectAttr(idd) {
      // this.attr = idd
      let ids = []
      let allSx = document.querySelectorAll("input[name='goodsAttr']:checked")
      allSx.forEach((element) => {
        ids.push(element.dataset.idd)
      })
      this.attr = ids.join(',')
      this.page = 1
      this.initGoodsList()
    },
    // fnCate(id1, id1_name, id2, id2_name, id3, id3_name) {
    //   this.page = 1
    //   if (id1 && !id2) {
    //     if (this.fCate == id1) {
    //       this.fCate = ''
    //       this.sCate = ''
    //       this.tCate = ''
    //       this.initGoodsList()
    //       return
    //     }
    //   }
    //   this.fCate = id1 || ''
    //   this.sCate = id2 || ''
    //   this.tCate = id3 || ''
    //   this.fCate_name = id1_name || ''
    //   this.sCate_name = id2_name || ''
    //   this.tCate_name = id3_name || ''
    //   this.initGoodsList()
    // },
    // initCate() {
    //   let params = {}
    //   goodsCateList(params).then((data) => {
    //     if (data.status == 200) {
    //       this.goodsCate = data.msg
    //     }
    //   })
    // },
    initGoodsList() {
      let params = {
        page: this.page,
        keyword: this.keyword,
        fCate: this.fCate,
        sCate: this.sCate,
        tCate: this.tCate,
        attr: this.attr,
      }
      goodsList(params).then((data) => {
        if (data.status == 200) {
          this.goods = data.msg
          this.total = data.count
        }
      })
    },
    initGoodsAttr() {
      let params = {}
      goodsAttr(params).then((data) => {
        if (data.status == 200) {
          this.goodsAttrObj = data.msg
        }
      })
    },
    changePage(childrenData) {
      this.page = childrenData;
      this.initGoodsList();
    }
  }

}
</script>


<style scoped>
.jia-icon {
  width: 24px;
  height: 24px;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid #979797;
  border-radius: 2px;
}

input[type="checkbox"]:checked {
  border: 2px solid #018ecf;
  background: url("@/assets/checked1.png") no-repeat center center;
  background-size: 18px 18px;
}

input[name="collect"] {
  position: absolute;
  right: 12px;
  top: 12px;
  z-index: 1;
}

.four-out {
  display: flex;
  flex-wrap: wrap;
}

.fourImg_out {
  padding: 10px 12px;
}

.fourImg {
  display: block;
  width: 100%;
  height: 150px;
  /* margin: 0 auto 6px; */
  transition: all 0.3s;
}

.four-out .four {
  margin-right: 54px;
  margin-bottom: 45px;
  position: relative;
  display: inline-block;
  width: calc((100% - 162px)/4);
  /* background: #F8F8F8; */
  box-sizing: border-box;
  /* padding: 6px 0 12px; */
  transition: all 0.3s;
  /* height: 300px; */
  border: 1px solid #EEEEEE;
}

.four-out .four:nth-child(4n) {
  margin-right: 0;
}

.f_tou {
  height: 36px;
  line-height: 36px;
}

.f_collect {
  width: 36px;
}

.f_collect_img {
  width: 24px;
  height: 24px;
}

.btn1 {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  line-height: 36px;
  height: 36px;
  background: #008dd1;
  border-radius: 36px;
  width: 82%;
  transition: all 0.3s;
  margin-bottom: 16px;
}

/* .four:hover .product-intro {
    display: block;
}

.four:hover .fourImg,
.four:hover .btn1 {
    display: none;
} */
.hover_all:hover .product-intro {
  display: inline-flex;
}

.hover_all:hover .goods_name {
  background: #005BAC;
  color: #fff;
}

.product-intro {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  margin: 0 auto;
  height: 100%;
  /* height: 40%; */
  overflow-y: scroll;
  display: none;
  /* margin-top: 20px; */
  padding: 0 20px;
  transition: all 0.3s;
  background: rgba(0, 0, 0, 0.5);
}

.product-intro::-webkit-scrollbar {
  display: none;
}


.goods_name {
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: #F3F3F3;
  font-size: 16px;
  color: #333333;
}

.collect {
  display: none;
  width: 28px;
  height: 28px;
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 1;
}

input[type="radio"] {
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid #979797;
  border-radius: 2px;
  margin-right: 16px;
}

input[type="radio"]:checked {
  border: 2px solid #018ecf;
  background: url("@/assets/checked1.png") no-repeat center center;
  background-size: 18px 18px;
}

.box {
  padding: 20px 45px;
  background-color: #fff;
  width: 1200px;
  margin: auto;
  box-sizing: border-box;
}

.bread {
  height: 44px;
  background: #f8f8f8;
  padding: 0 60px;
}

.color-2A76A8 {
  color: #2A76A8;
  font-size: 16px;
}

.ppp {
  position: absolute;
  right: -4px;
  top: 8px;
  -webkit-appearance: none;
  width: 24px;
  height: 24px;


  z-index: 1;
}
</style>