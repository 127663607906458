const English = {

    JC:'US',
    JCs:'us',
    hello: 'Hello',
    headXJD: 'Inquiry Sheet',
    headSY: 'Homepage',
    headQYCP: 'Company Products',
    headGYWM: 'About US',
    headLXWM: 'Contact US',
    headYSZC: 'Private Policy',
    headSSCP: 'Product Search',
    headGRZX: 'Account Information',
    headYY: 'English',
    headXBYY: 'Español',
    headPTYY: 'Português',
    mainCPFL: 'Product Category',
    mainTJCP: 'Recommended  products',
    mainTJCPYH: 'Recommended the latest favorable  products',
    mainCK: 'Review',
    content:'',
    new: {
        prev: 'Previous',
        next: 'Next'
    },


    other:{
        cart:'Shopping Cart',
        addedSuccess:'Added successfully!',
        contBrowse:'Continue browsing',
        toCart: 'Go to shopping cart',
        cancel:'Cancel',
        confirm:'Confirm',
        success:'Copy Successful',
        fail:'Copy Failed',

        sure1:'Are you sure you want to confirm the order?',
        sure2:'Would you like to cancel the order?',
        sure3:'Do you confirm the receipt of the goods?',
        confirmOrder:'Confirm order'

    },


    home:{
        help:'Help',
        shop:'MolChem.shop',
        platform:'Chemical Procurement Platform',
        safe:'Safe and transparent',
        place:'Please Enter CAS No. or Product Name',
        allProducts:'All Products',
        productsTotal:'Products Total',
        completedTotal:'Completed Orders Total',
        recommendation:"Today's Recommendation",
        what:'What We Do?',
        doWhat:'Was established with the aim to build the most comprehensive database of chemical products and contain most complete suppliers, making chemical trading more efficient, convenient and safe. facilitate global chemical trading and bring chemical buyers & sellers from all over the world to one common chemical platform.',
        why:'Why Choose Us？',
        why1:'Complete categories and sufficient inventory;',
        why2:'More reasonable prices;',
        why3:'Transparent operational procedures;',
        hot:'Popular Products',
        cate:'Products For Categories',
        buy:'How To Buy',
        language:'Language',
        eng:'English',
        pro:'Portuguese',
        spa:'Spanish',
        page:'Home Page',
        copyright:'Copyright © 2024 MolChem.shop All rights reserved.'


    },
    footAll: {
        rmlb: 'Hot Products',
        fwzc: 'Service Policy',
        sjbhzc: 'Data Protection Policy',
        yszc: 'Private Policy',
        gywm: 'About US',
        gsjs: 'Company Profile',
        lxwm: 'Contact US',
        dz: 'address',
        zb: 'headquarters',
        js: 'Introduction',
        news: 'News & Events'
    },
    headGRZXObj: {
        jbxx: 'Basic Information',
        dzgl: 'Receiving Address',
        wdsc: 'Collected Products',
        qbdd: 'All orders',
        dqr: 'To be confirmed',
        dfk: 'To be paid',
        dfh: 'To be shipped',
        dsh: 'To be received',
        ywc: 'Received ',
        yqx: 'Cancelled ',
        tczh: ' Logout ',
        zhzx: 'Member Information',
        ddzx: ' Order Center',
    },
    jbXX: {
        xing: 'Family Name',
        ming: 'Surname',
        dzyx: 'Email',
        gs: 'Company',
        zw: ' Position',
        dhhm: 'Telephone Number',
        tj: 'Add',
        mm: 'Password',
        ggmm: 'Change password ',
        ndqmm: 'Current password',
        ndxmm: 'New password',
        cxsrndxmm: 'Re-enter your password',
        qsr: 'Please enter',
        lcbyz: 'Two new password inputs are inconsistent',

        email:'E-mail (Also Member Account)',
        name:'Your Name',
        country:'Country or Region',
        corporate:'Corporate Name',
        address:'Your Company Address',
        contact:'Contact Information',
        contactPlace:'Your Telphone Or Email',
        cpwd:'Enter Current Password',
        npwd:'Enter New Password',
        rpwd:'Repeat Password',
        confirm:'Confirm',
        


        
    },
    addressObj: {
        mo: 'Set as default address',
        xdz: 'New address',
        xxdz: 'Detailed address',
        yb: 'Post Code',
        dzgl: 'Address management',
        mrdz: 'Default address',
        qr: 'Confirmation',
        bjdz: 'Edit address',


        addAddress: 'Add Address',
        setDefault: 'Set Default',
        cancelDefault: 'Cancel Default',
        edit: 'Edit',
        editInformation: 'Edit Information',
        shippingAddress: 'Shipping Address',
        shipmentPort: 'Shipment Port',



    },
    cateObj: {
        lb: 'Category ',
        sx: 'Product Attribute'
    },
    goodsObj: {
        bz: 'Packing ',
        sl: 'Quantity',
        xzgg: 'Choose grade',
        ljgm: 'Buy now',
        zj: 'Total',
        jrgwc: 'Add to shopping cart',
        cpxq: 'Product Details',
        tjsc: 'Add Saving',
        qxsc: 'Cancel Saving',


        buy:'Purchase',
        toCart:'Add to Cart',
        amountTotal: 'Amount Total',
        buyNow: 'Buy Now',
        moQ: 'MOQ',




    },
    carObj: {
        sc: 'Delete',
        qx: 'Select all',
        cpxx: 'Product Information',
        cpgg: 'Product Specification',
        dj: ' Unit price',
        sl: 'Quantity',
        xj: 'Subtotal',
        gwczj: 'Total Shopping Cart',
        je: ' Amount',
        tj: 'Submit',
        xz: 'Please select the deleted product',
        qrsc: 'Confirm deletion',
        qux: 'Cancel',
        qr: 'Confirmation',
        xzcp: 'Please select a product',
        tjdd: 'Submit order',
        cpqd: 'Product List ',
        tjdz: 'Add address',
        qxzdz: 'Please choose address',


        tip:'tip',
        noOtherPrice:'This price does not include shipping and other fees.',
        calcPrice:'After placing the order, we will calculate the accurate price for you.',
        confirmSA:'Confirm shipping address'
 





    },
    orderObj: {
        ddxx: 'Order Information',
        gg: 'Specification',
        zt: 'Status',
        cz: 'Operation',
        ddsj: 'Order Date',
        ddbh: 'Order number',
        ckdd: 'View order',
        qrdd: 'Confirm Order',
        qxdd: 'Cancel Order',
        qrsh: 'Confirming receipt of goods',
        zlyd: 'Please place an order again ',
        ddxq: 'Order Details',
        qm: 'full name',
        dh: 'Telephone',
        wl: 'Logistics',
        w: 'None',
        hwys: 'Transportation',


        allPurOrder: 'All purchase orders',
        searchP: 'Enter Order ID. or Product Name',
        search: 'search',
        orderProcess: 'Order Processing Process',
        order1:'Price Accounting',
        order2:'Confirm Order',
        order3:'Payment',
        order4:'Send out goods',
        order5:'Waiting for delivery',
        order6:'Confirm Receipt',
        order_intro1: 'After generating the order,the seller calculates the total price of the order.',
        order_intro2: 'The buyer confirms the list of goods and price information.',
        order_intro3: 'The buyer pays the purchase price.',
        order_intro4: 'After receiving the payment, the seller arranges for shipment.',
        order_intro5: 'The buyer is waiting for receipt of the goods.',
        order_intro6: 'The buyer inspects the goods and confirms receipt of the goods.',
        filter:'Order filtering: Presenter',
        reorder:'Reorder',
        goodsPrice:'Price of goods',
        otherExpenses:'Other Expenses',
        waitAccount:'Waiting for accounting',
        totalAmount:'Total Amount',
        presenter:'Presenter',
        orderID:'Order ID',
        orderStatus:'Order Status',
        wlIntro1:'Goods have been shipped, logistics information:',
        wlIntro2:'We have received the payment and will ship soo.',
        wlIntro3:'Your formal order has been generated, please confirm and pay the payment as soon as possible.',
        shipAddress:'shipping address',
        emailOnly:'E-mail',
        address:'Add',
        tariff:'Tariff',
        tranCosts:'Transportation costs',
        sRequire:'special requirements',
        sRequireXq:'If there are special requirements, please fill in here.',
        





    },
    logon: {
        dl: 'Login',
        dl1: '',
        dl2: 'Place an order quickly',
        dl3: 'Directly connect with the most favorable price',
        dl4: 'Efficient recovery',
        dl5: 'Please enter email',
        dl6: ' Please enter password',
        dl7: 'Forget password',
        dl8: 'Do not have an account? Register as a',
        dl9: 'Please enter verification code',
        dl10: 'Please enter password again',
        dl11: 'Please send verification code',
        dl12: 'Verification error',
        dl13: 'Two input passwords are inconsisten',
        dl14: 'Send successfully',
        dl15: 'Retrieve',
        dl16: 'Complete data',
        dl17: 'Please enter your surname',
        dl18: ' Please enter your name',
        dl19: ' Please enter your email',
        dl20: 'Please enter telephone number',
        dl21: 'Please enter company name ',
        dl22: 'Please enter position',
        dl23: 'In verification',
        dl24: 'Submit again',
        dl25: 'Submit',
        dl26: 'Remind',
        dl27: 'Verification failure',
        dl28: 'In verification, please wait patiently',
        d129:'User',
        d130:'Password',
        d131:'Name',
        d132:'Email',
        d133:'Telephone',
        d134:'Company',
        d135:'Position',
        d136:'Buyer',


        already:'If you are already a member of molchem.shop please login below:',
        email:'E-mail Address:',
        emailP:'Enter Your E-mail',
        enterPassword:'Enter Password',
        forgotten:'Forgotten Password?',
        account:"Don't have an account yet?",
        resetPwd:'reset password',
        resetPwdTxt:'Obtain verification code through email to reset password.',
        verCode:'Verification Code:',
        enterCode:'Enter Verification Code',
        code:'Get Code',
        local:'Local: Home',
        resetPwd1:'Reset password',
        registration:'Registration',
        registerTxt1:'Here you can register free of charge for my.chemeurope.com. With the registration you will receive your personal access to many useful features.',
        registerTxt2:'The e-mail address you enter will also be your user name.',
        registerTxt3:'By creating an account, you agree to MOLBASE’s Conditions of Use and ',
        notice:'Privacy Notice',
        register:'Register',
        anAccount:'Already have an account?',
        sign:'Sign in>',
        regSuccess:'Registered successfully!',
        completeInfo:'Please go to your member center to complete member information.',
        ok:'OK',




        
    },
    xjd: {
        txxjd: 'Fill in inquiry sheet',
        xjdlb: 'Inquiry sheet list ',
        xjcp: 'Inquired products',
        pthf:'Platform reply',
        lxr:'Contact',
        lxrxx:'Contact phone number',
        srlxr:'Enter contact',
        srlxrxx:'Enter contact phone number',
        cpsl:'Fill in inquired product name and  quantity',
        myg:'Arrival date at port',
        cyg:'Shipment Port',
        mdg:'Destination Port',
        ysfs:'Payment terms',
        mysysj:'Trade Terms',
        qxz:'Please select',
        xzbz:'Select Packing',
        txsl:'Fill in quantity',
        xzhb:'Select Currency ',
        cgoods:'Select Product',
        scdd:'Generate Order',
        addd:'Add',
        tishi:'Remind',
        qx:'Cancel',
        cg:'Determine',
        tsxx:'This operation will permanently delete the change record. Do you want to continue?',
        sccg:'Successfully deleted',
        qxsc:'Cancel deletion',
        ss:'Search',
        xjdbh:'Inquiry Sheet Number',
        cggsmc:'Company Name',
        cggsdz:'Company Address',
        cgyxm:' Buyer’s Name',
        cgydh:'Buyer’s Telephone',
        yjdz:'Email',
        dj:'Unit Price',
        zongjia:'Total Price',
        dw:'Unit',
        txdw:'Fill in Unit',
        cp:'Product',
    }
}
export default English