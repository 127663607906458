<template>
  <div id="main" class="bg-fff1">
    <div ref="top111">
      <Header />
      <div class="flex-wrap" style="height: 60px;width: 1200px;margin: 24px auto 0;">

        <CateList />


<div class="align-start comp border-box">
      <img :src="require('@/assets/home/num.png')" fit="cover" alt=""
        style="width: 32px;height: 32px;margin-right: 10px;" />
        <div>
          <div class="font-005BAC-16 m_b3">{{ $t('home.productsTotal') }} <span class="bold">{{count.count1||0}}</span></div>
          <div class="font-005BAC-16">{{ $t('home.completedTotal') }} <span class="bold">{{count.count2||0}}</span></div>
        </div>
    </div>

    <div class="flex-con font-fff-18-bold bg-317EE7 align-center">
      {{ $t('home.recommendation') }}
    </div>



</div>
      <div style="background: #fff;overflow: hidden;">
        <div style="width:1200px;margin: auto;" class="align-start">

          <div class="swiper-container" style="width:690px; border-radius: 0;height: 396px;">
            <div id="swiper-container1" style="height:100%;">
              <div class="swiper-wrapper" style="height:100%;">
                <div class="swiper-slide pointer" v-for="(item, index) in banner" :key="'banner_' + index"
                  @click="fnGoodsDetails(item.banner_id)" style="height:100%;">
                  <img :src="item.banner_image" style="height: 396px;width: 100%;" />

                </div>
              </div>
              <div class="swiper-pagination" style=""></div>
            </div>

          </div>
          <div style="width: 510px;height: 396px;padding:15px 19px 0 15px;" class="border-box">
            <!-- <div class="" style="font-size: 16px;">{{ $t('mainTJCP') }}</div> -->
            <div class="top_right" @click="fnGoodsDetails(item.goods_id)" v-if="index < 3"
              v-for="(item, index) in recommendGoodsData" :key="'goods_' + item.goods_id + '_' + index">
              <div class="flex-wrap">
                <div style="width: 96px;height: 85px;">
                  <el-image fit="contain" :src="item.goods_thumb" class="fourImg1"></el-image>
                </div>
                <div class="flex-con flex-wrap flex-vertical" style="margin-left: 15px;">
                  <div class="top_right1 aui-ellipsis-2">
                    {{ $i18n.locale == 'en' ? item.goods_name_en
                      : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}
                  </div>
                  <div class="flex-con"></div>
                  <div class="top_right2 align-end" v-show="user_id">
                    <div class="top_right2_btn align-center">
                      $ {{ item.goods_cu_price || '' }} / {{ item.goods_unit || '' }}
                    </div>

                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>

        <div class="flex-wrap">
          <div class="bg1 align-end-top">
            <div class="bg1_txt border-box" style="width: 600px;padding:40px 50px 0 40px;">
              <div class="font-fff-24-bold m_b28">{{ $t('home.what') }}</div>
              <div class="font-fff-16" style="line-height: 28px;">{{ $t('home.doWhat') }}</div>
            </div>
          </div>
          <div class="bg2">
            <div class="bg2_txt border-box" style="width: 600px;padding:40px 0 0 50px;">
              <div class="font-fff-24-bold m_b30">{{ $t('home.why') }}</div>
              <div class="font-fff-18 align-start m_b28"><img src="@/assets/home/right.png"
                  style="width: 24px;height: 24px;margin-right:10px;" alt=""> {{ $t('home.why1') }}</div>
              <div class="font-fff-18 align-start m_b28"><img src="@/assets/home/right.png"
                  style="width: 24px;height: 24px;margin-right:10px;" alt=""> {{ $t('home.why2') }}</div>
              <div class="font-fff-18 align-start"><img src="@/assets/home/right.png"
                  style="width: 24px;height: 24px;margin-right:10px;" alt=""> {{ $t('home.why3') }}</div>
            </div>
          </div>
        </div>


        <div class="product-recommend bg-fff1 border-box" style="width:1200px;padding: 42px 0 20px;margin: auto;">
          <div class="flex-wrap m_b34">
            <img src="@/assets/home/hot.png" style="width: 40px;height: 65px;margin-right:20px;" alt="">
            <div class="flex-con font-333-24-500 bold align-start">{{ $t('home.hot') }}</div>
            <div class="b-b-eee" style="width: 820px;height: 32px;"></div>
          </div>


          <div class="four-out">
            <div class="four pointer relative" @click="fnGoodsDetails(item.goods_id)"
              v-for="(item, index) in recommendGoodsData" :key="'goods_' + item.goods_id + '_' + index">
              <div class="f_tou" v-show="user_id">
                <div class="font-333-16 flex-con" style="line-height: 36px;background: #FFC800;">
                  <div class="font-333-16-500" style="padding-left: 12px;line-height: 36px;">
                    {{ $t('JC') }}
                    $ {{ item.goods_cu_price || '' }}
                  </div>
                </div>
              </div>
              <div class="hover_all">
                <div class="fourImg_out">
                  <div class="relative">
                    <el-image fit="cover" :src="item.goods_thumb" class="fourImg"></el-image>
                    <div class="font-fff-16 product-intro align-center1 center">
                      {{ $i18n.locale == 'en' ? item.goods_intro_en
                        : $i18n.locale == 'es' ? item.goods_intro_es : item.goods_intro_tp }}
                    </div>
                  </div>
                </div>
                <div class="aui-ellipsis-1 goods_name">
                  {{ $i18n.locale == 'en' ? item.goods_name_en
                    : $i18n.locale == 'es' ? item.goods_name_es : item.goods_name_tp }}
                </div>
              </div>
            </div>
          </div>




        </div>

        <div class="bg3">

          <div class="cate_all" style="padding:30px 0 4px;width:1200px;margin: auto;">
            <div class="flex-wrap m_b30">
              <img src="@/assets/home/cate.png" style="width: 46px;height: 46px;margin-right:24px;" alt="">
              <div class="flex-con font-333-24-500 bold align-start">{{ $t('home.cate') }}</div>
              <div class="b-b-eee" style="width: 820px;height: 32px;"></div>
            </div>


            <div class="cate_out" style="display: flex;flex-wrap: wrap;">
              <div class="cate_item pointer border-box" v-for="(item, index) in allFirstGoodsCate"
                :key="'allFirstGoodsCate_' + index" @click="fnToCate(item.cate_id, $i18n.locale == 'en' ? item.cate_name_en
                  : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp)">

                <img :src="item.cate_image" alt="">

                <div class="product-fl-title aui-ellipsis-1">
                  {{ $i18n.locale == 'en' ? item.cate_name_en
                    : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp }}
                </div>

              </div>

            </div>




          </div>


        </div>




        <!-- <div class="w1200" style="margin-top: -60px;">
          <div v-for="(item, index) in goodsData" :key="'goods_' + item.cate_id + '_' + index">
            <div class="font-333-50-600  m_b36" style="margin-top: 90px;">
              <span class="b-b-333">#{{ $i18n.locale == 'en' ? item.cate_name_en
                : $i18n.locale == 'es' ? item.cate_name_es : item.cate_name_tp }}</span>
            </div>
            <div
              style="margin-top: -16px;font-size: 16px;color: #333333;width: 1200px;word-wrap: break-word;line-height: 22px;box-sizing: border-box;text-align: left;">
              {{ $i18n.locale == 'en' ? item.cate_desc_en
                : $i18n.locale == 'es' ? item.cate_desc_es : item.cate_desc_tp }}
            </div>
            <div class="b-b-979797 p_b60" style="margin-top: 20px;">
              <div class="relative m_b36 tab_out1">
                <div class=" font-666-22 align-start nowrap">
                  <div class="pointer tab_in" :class="{ active: index == tabIndex }"
                    v-for="(item1, index1) in item.secondCate" :key="'tabData_' + index1" @click="fnTab(index1)"
                    :style="index == (item.secondCate.length - 1) ? 'margin-right:0' : ''">
                    {{ $i18n.locale == 'en' ? item1.cate_name_en
                      : $i18n.locale == 'es' ? item1.cate_name_es : item1.cate_name_tp }}
                  </div>
                </div>
                <div class="white-meng"></div>

              </div>
              <div class="flex-wrap">
                <div class="flex-con two-out"
                  style="width: 1200px;border-radius: 5px;background-color: #FAFCFF;box-sizing: border-box;padding: 10px;display: flex;flex-wrap: wrap;gap: 19px;">
                  <div class="two center pointer relative" v-for="(item2, index2) in item.goods"
                    :key="'goods1_' + item2.goods_id + '_' + index" v-if="index < 6"
                    @click="fnGoodsDetails(item2.goods_id)">
                    <el-image style="width: 20px; height: 20px; margin-right: 16px" class="ppp" :src="item2.isCollect == 1
                      ? require('@/assets/star1.png')
                      : require('@/assets/star2.png')
                      " fit="cover"></el-image>
                    <div class="twoImg_out align-center">
                      <img :src="item2.goods_thumb" class="twoImg" alt="">
                    </div>
                    <div style="padding: 0 12px">
                      <div class="font-666-28 aui-ellipsis-2 h56" style="margin-top: -14px;">
                        {{ $i18n.locale == 'en' ? item2.goods_name_en
                          : $i18n.locale == 'es' ? item2.goods_name_es : item2.goods_name_tp }}
                      </div>
                      <div class="center font-333-20-500" style="margin-top: 7px;line-height: 16px;font-weight: bold;">
                        $ {{ item2.goods_cu_price || '' }}
                      </div>
                    </div>
                  </div>
                </div>



              </div>
            </div>
          </div>

        </div> -->
      </div>
    </div>
    <Footer />
    <Icon />
  </div>
</template>
<script>
import Icon from '@/components/Icon.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import CateList from '@/components/CateList.vue'
import Swiper from 'swiper/bundle'
import { bannerList, firstGoodsCate, indexGoodsData, recommendGoods, goodsCateList } from '../api'
export default {
  components: {
    Header,
    Footer,
    CateList,
    Icon
  },
  data() {
    return {
      banner: [],
      count:{},
      allFirstGoodsCate: [],
      recommendGoodsData: [],
      user_id: localStorage.getItem("allUserMr")?JSON.parse(localStorage.getItem("allUserMr")).user_id:0
    }
  },

  mounted() {
    this.initLB();
    this.initFirstGoodsCate();
    this.initRecommendGoods();
    new Swiper('#swiper-container1', {
      autoplay: true,
      observer: true,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
    })
  },
  methods: {
    fnToCate(cate_id1, cate_id1_name) {
      this.$router.push({
        name: 'cate',
        params: {
          id1: cate_id1,
          id1_name: cate_id1_name
        }
      })
    },
    initRecommendGoods() {
      let params = {}
      recommendGoods(params).then((data) => {
        if (data.status == 200) {
          this.recommendGoodsData = data.msg
        }
      })
    },
    initFirstGoodsCate() {
      let params = {
        type: 1
      }
      firstGoodsCate(params).then((data) => {
        if (data.status == 200) {
          this.allFirstGoodsCate = data.msg
        }
      })
    },
    initLB() {
      let params = {}
      bannerList(params).then((data) => {
        if (data.status == 200) {
          this.banner = data.msg
          this.count=data.count
        }
      })
    }
  }
}
</script>

<style scoped>
.bg1 {
  width: 50%;
  min-height: 292px;
  background: url('@/assets/home/bg1.png') no-repeat center center;
  background-size: 100% 100%;
}

.bg2 {
  width: 50%;
  min-height: 292px;
  background: url('@/assets/home/bg2.png') no-repeat center center;
  background-size: 100% 100%;
}

.bg3 {
  width: 100%;
  min-height: 663px;
  background: url('@/assets/home/bg3.png') no-repeat center center;
  background-size: 100% 100%;
}


.btn1 {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  line-height: 36px;
  height: 36px;
  background: #008dd1;
  border-radius: 36px;
  width: 82%;
  transition: all 0.3s;
  margin-bottom: 16px;
}

.hover_all:hover .product-intro {
  display: inline-flex;
}

.hover_all:hover .goods_name {
  background: #005BAC;
  color: #fff;
}

.product-intro {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 0;
  height: 100%;
  /* width: 100%; */
  /* height: 40%; */
  overflow-y: scroll;
  display: none;
  /* margin-top: 20px; */
  padding: 0 20px;
  transition: all 0.3s;
  background: rgba(0, 0, 0, 0.5);
}

.product-intro::-webkit-scrollbar {
  display: none;
}


.goods_name {
  height: 36px;
  line-height: 36px;
  text-align: center;
  background: #F3F3F3;
  font-size: 16px;
  color: #333333;
}

/* .product-intro::-webkit-scrollbar {
  display: none;
} */

.collect {
  display: none;
  width: 28px;
  height: 28px;
  position: absolute;
  right: 22px;
  top: 22px;
  z-index: 1;
}

/* input[type='checkbox'] {
  position: absolute;
  right: 8px;
  top: 8px;
  -webkit-appearance: none;
  width: 24px;
  height: 24px;
  border: 2px solid #018ECF;
  border-radius: 2px 0 0 2px;
  z-index: 1;
} */

/* input[type='checkbox']:checked {
  border: 2px solid #018ECF;
  background: url('@/assets/checked1.png') no-repeat center center;
  background-size: 18px 18px;
} */

.two {
  position: relative;
  display: inline-block;
  width: 220px;
  height: 318px;
  background: #FFFFFF;
  box-sizing: border-box;
  padding-top: 36px;
  transition: all 0.3s;
}

.twoImg_out {
  display: block;
  width: 220px;
  height: 220px;
  margin: -34px auto 20px;
  position: relative;
}

.twoImg_out .twoImg {
  object-fit: cover;
  display: block;
  width: 100%;
  height: fit-content;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.two-out .two {
  margin-bottom: 6px;
}

/* .two-out .two:nth-child(4n+2) {
  margin: 0 14px;
}
.two-out .two:nth-child(4n+4) {
  margin: 0 0 0 14px ;
} */

.four-out {
  display: flex;
  flex-wrap: wrap;
}

.fourImg_out {
  padding: 10px 12px;
}

.fourImg {
  display: block;
  width: 100%;
  height: 150px;
  /* margin: 0 auto 6px; */
  transition: all 0.3s;
}

.fourImg1 {
  display: block;
  width: 100%;
  height: 100%;
  /* 
  transition: all 0.3s; */
}

.four-out .four {

  margin-right: 84px;
  margin-bottom: 45px;
  position: relative;
  display: inline-block;
  width: 237px;
  /* background: #F8F8F8; */
  box-sizing: border-box;
  /* padding: 6px 0 12px; */
  transition: all 0.3s;
  /* height: 300px; */
  border: 1px solid #EEEEEE;
}

.four-out .four:nth-child(4n) {
  margin-right: 0;
}

.f_tou {
  height: 36px;
  line-height: 36px;
}

.material-bg {
  width: 319px;
  height: 600px;
  background-color: #F9FCFF;
  background-size: 100% 806px;
  transition: all 0.3s;
}

.material-bg:hover,
.two:hover {
  box-shadow: 0px 0px 20px 0px rgba(42, 118, 168, 0.2);
}

.material-bg img {
  width: 319px;
  height: 319px;
}

.white-meng {
  width: 68px;
  height: 30px;
  position: absolute;
  top: 0;
  right: 0;
  background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.15) 100%);
  /* background: url('@/assets/swipe_jt_r2.png') no-repeat center center, linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0.15) 100%); */
  /* background-color: #000;
  background-image: url('@/assets/swipe_jt_r2.png');
  background-repeat: no-repeat;
  background-position: center center; */
  /* background-repeat: no-repeat;
  background-size: 22px 22px; */
}



/* .tab_out::-webkit-scrollbar {
  display: none;
  transition: all 2s;
} */

/* 
.tab_out1:hover .tab_out::-webkit-scrollbar {
  display: block;
} */

/* .tab_out::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}

.tab_out::-webkit-scrollbar-corner {
  background-color: transparent;
}

.tab_out::-webkit-scrollbar-thumb {
  background-clip: padding-box;
  background-color: rgba(0, 0, 0, 0.2);
} */

.tab_out {
  overflow-x: scroll;
}

.tab_out>div {
  margin-right: 50px;
}

.tab_out .active,
.tab_in:hover {
  font-weight: 500;
  color: #018ECF;
}

.product-fl .product-fl-img {
  width: 220px;
  height: 220px;
  position: absolute;

  top: 0px;
  object-fit: cover;

}

.product-fl .product-fl-jt_right {
  width: 32px;
  height: 32px;
  position: absolute;
  bottom: 16px;
  left: 20px;
}

.product-fl {
  width: 220px;
  height: 300px;
  position: relative;

  box-sizing: border-box;
}

.product-fl-in {
  box-sizing: border-box;
  width: 220px;
  height: 300px;
  background: #F2F9FC;
  border-radius: 4px;
  padding-top: 40px;
  transition: all 0.3s;
  position: relative;
  opacity: 1;

}

.product-fl:hover .product-fl-in {
  background: #018ECF;
}

.top_right {
  width: 100%;
  margin-bottom: 18px;
  box-sizing: border-box;
  padding: 12px;
  height: 110px;
  background: #F7F7F7;
  color: #005BAC;
  font-size: 14px;
}

.top_right:last-child {
  margin-bottom: 0;
}

.top_right:hover {
  background: #E8F2FF;
  cursor: pointer;
}

.top_right1 {
  line-height: 25px;
}

.top_right2 .top_right2_btn {
  width: 128px;
  height: 24px;
  background: #EEEEEE;
  border-radius: 12px;
}

.top_right:hover .top_right2_btn {
  background: #FFC800;
}

.product-fl:hover .product-fl-title {
  color: #FFFFFF;
}

.cate_item {
  border: 1px solid #EEEEEE;
  background-color: #FFFFFF;
  width: 237px;
  display: inline-block;
  box-sizing: border-box;
  margin-bottom: 40px;
  margin-right:84px;
  padding-top:15px;
}

.cate_item img{
  display: block;
  margin: 0 auto;
  width: 213px;height: 160px;
}

.cate_item:hover {
  background: #E8F2FF;
  border: 1px solid #005BAC;
}

.cate_out .cate_item:nth-child(4n) {
  margin-right: 0;
}




.product-fl-title {
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  text-align: center;
  line-height: 60px;
}

.comp{
  width: 413px;
height: 60px;
background: #F3F3F3;
padding-left: 17px;
}
</style>

<style scoped>
@import url('swiper/swiper-bundle.css');

.swiper-container {

  overflow: hidden;
}


.swiper-slide img {
  width: 100%;
  height: 100%;
}

.swiper-pagination {
  width: 100%;
  height: 15px;
  position: absolute;
  bottom: 17px;
  text-align: left;
  padding-left: 19px;
  box-sizing: border-box;
}




.swiper-button-prev:after {
  display: none;
}

.swiper-button-next:after {
  display: none;
}

.swiper-button-prev {
  width: 60px;
  height: 60px;
  background: url('../assets/swipe_jt_l1.png') no-repeat center center;
  background-size: 100px 100px;
  border-radius: 50%;
  top: calc(100%/2 - 80px);
}

.swiper-button-next {
  width: 60px;
  height: 60px;
  background: url('../assets/swipe_jt_r1.png') no-repeat center center;
  background-size: 100px 100px;
  top: calc(100%/2 - 80px);
  border-radius: 50%;

}

::v-deep .swiper-pagination-bullet {
  width: 15px;
  height: 15px;
  background: #176AB4;
  border-radius: 50%;
  display: inline-block;
  opacity: 1;
}

::v-deep .swiper-pagination-bullet-active {
  width: 15px;
  height: 15px;
  background: #FFC800;
  display: inline-block;
  border-radius: 50%;
}

::v-deep .swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin-right: 11px;
  margin-left: 0;
}

.font-666-28 {
  font-size: 18px;
}

.font-333-20-500 {
  font-size: 16px;
  margin-top: 6px;
}

.ppp {
  position: absolute;
  right: -4px;
  top: 8px;
  -webkit-appearance: none;
  width: 24px;
  height: 24px;


  z-index: 1;
}
</style>
