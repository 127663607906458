<template>
  <div class="bg-fff">
    <Header/>
    <!-- <div class="bread align-start font-333-18-500">
      <div>{{ $t('headSY') }}</div>
      <img :src="require('@/assets/swipe_jt_r2.png')" alt="" style="width:22px;height:22px;margin:0 20px;">
      <div class="color-2A76A8">{{ type == 1 ? $t('footAll.sjbhzc') : type == 2 ? $t('footAll.yszc') : type == 3
      ? $t('footAll.gsjs') : type == 4 ? $t('footAll.lxwm') : type == 7 ? $t('headGYWM') : '' }}</div>
    </div> -->
    <div class="flex-wrap" style="height: 60px;width: 1200px;margin: 30px auto 42px;">
      <CateList />

      <div class="flex-con align-end font-999-16 bg-fff1 p_r18">
        {{ $t('logon.local') }} > {{ $t('footAll.news') }}
      </div>

    </div>
    <div class="flex-wrap box11 margin">
      <div class="left1">
        <AboutUs :num111="2" />
      </div>
      <div class="box flex-con">
        <div class="font-005BAC-24-500 align-center bold" style="margin-bottom:14px;">
          <div>{{ $t('footAll.news').toUpperCase() }} <span class="xian"></span> </div>
        </div>
        <div style="padding: 0 35px 0 110px;">
          <div class="flex-wrap pointer border-box" v-for="(item,index) in data" :key="index" style="height: 244px;border-bottom: 1px dotted #ccc;padding-top: 40px;" @click="fnNewsDetails(item.n_id)">
            <el-image fit="cover" v-show="item.n_image" :src="item.n_image"
              style="height:162px;min-width: 242px;margin-right: 20px;"></el-image>
            <div class="flex-con">
              
              <div class="aui-ellipsis-1 font-666-18-500 bold">
                {{ $i18n.locale == 'en' ? item.n_title_en
                        : $i18n.locale == 'es' ? item.n_title_es : item.n_title_tp }}</div>
              <div class="font-A2A2A2-18" style="margin: 14px 0;">{{ item.n_time.slice(0, 10) }}</div>
              <div class="font-666-14 aui-ellipsis-4" v-show="item.n_image" style="line-height: 24px;">
                {{ $i18n.locale == 'en' ? item.n_desc_en
                        : $i18n.locale == 'es' ? item.n_desc_es : item.n_desc_tp }}
                </div>
              <div class="font-666-14 aui-ellipsis-3" v-show="!item.n_image" style="line-height: 24px;">
                {{ $i18n.locale == 'en' ? item.n_desc_en
                        : $i18n.locale == 'es' ? item.n_desc_es : item.n_desc_tp }}
              </div>
            </div>
          </div>
          <div class="align-center m_t12" v-show="total > 0">
            <Page :limit="limit" :totalNum="total" @onPage="changePage" />
          </div>
        </div>
      </div>
    </div>
    <Footer />
    <Icon />
  </div>
</template>
<script>
import Icon from '@/components/Icon.vue'
import CateList from '@/components/CateList.vue'
import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import AboutUs from '@/components/AboutUs.vue'
import Page from '@/components/Page.vue'
import { limit, newsList } from '../api'
export default {
  components: {
    Header,
    Footer,
    AboutUs,
    Page,
    CateList,
    Icon
  },
  data() {
    return {
      page: 1,
      limit: limit,
      total: 0,
      data:[]
    }
  },
  methods: {
    
    initData() {
      newsList({
        page: this.page
      }).then((data) => {
        if (data.status == 200) {
          this.data=data.msg
          this.total = data.count;
        }
      })
    },
    fnNewsDetails(idd) {
      this.$router.push({
        name: 'news_details',
        params: {
          id: idd
        },
      })
    },
    changePage(childrenData) {
      this.page = childrenData;
      this.initData();
    },
  },
  created() {
  
  },
  mounted() {
    this.initData()
  },

}
</script>


<style scoped>
.bread {
  height: 45px;
  background: #f8f8f8;
  padding: 0 60px;
}

.box {
  background: #fff;
  /* padding: 0 35px 0 110px; */
  box-sizing: border-box;
}

.box11 {
  padding: 26px 20px;
  width: 1200px;
  box-sizing: border-box;
  background-color: #fff;
}

.title {
  font-size: 24px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 15px;
  box-sizing: border-box;
  text-align: center;
}

.left1 {
  width: 200px;
  /* margin-right: 30px; */
}
</style>