import http from '../utils/request'

export const limit = 12

export const goodsCateList = (data) => {//商品分类---三级
    return http.post('Goodscate/goodsCateList', data)
}

export const firstGoodsCate = (data) => {//商品一级分类
    return http.post('Goodscate/firstGoodsCate', data)
}

export const bannerList = (data) => {//轮播图列表
    return http.post('Banner/bannerList', data)
}

export const indexGoodsData = (data) => {//首页分类商品
    return http.post('Goods/indexGoodsData', data)
}

export const recommendGoods = (data) => {//首页推荐商品
    return http.post('Goods/recommendGoods', data)
}

export const sendCode = (data) => {//发送邮箱验证码
    return http.post('Apilogin/sendCode', data)
}

export const emailRegister = (data) => {//邮箱注册
    return http.post('Apilogin/emailRegister', data)
}

export const mrLogin = (data) => {//邮箱密码登录
    return http.post('Apilogin/login', data)
}

export const getConfig = (data) => {//项目配置项
    return http.post('Other/getConfig', data)
}

export const userBaseInfo = (data) => {//用户基本信息
    return http.post('User/userBaseInfo', data)
}

export const commitCheck = (data) => {//提交审核信息
    return http.post('User/commitCheck', data)
}

export const changePwd = (data) => {//修改密码
    return http.post('User/changePwd', data)
}

export const createAddress = (data) => {//新增收货地址
    return http.post('Address/createAddress', data)
}

export const addressList = (data) => {//收货地址列表
    return http.post('Address/addressList', data)
}

export const showAddress = (data) => {//收货地址详情
    return http.post('Address/showAddress', data)
}

export const updateAddress = (data) => {//编辑收货地址
    return http.post('Address/updateAddress', data)
}

export const delAddress = (data) => {//删除收货地址
    return http.post('Address/delAddress', data)
}

export const doCollect = (data) => {//收藏/取消收藏
    return http.post('User/doCollect', data)
}

export const myCollect = (data) => {//我的收藏列表
    return http.post('User/myCollect', data)
}

export const goodsAttr = (data) => {//商品属性
    return http.post('Goods/goodsAttr', data)
}

export const goodsList = (data) => {//商品列表
    return http.post('Goods/goodsList', data)
}

export const shopCarNumber = (data) => {//购物车数量
    return http.post('Shopcar/shopCarNumber', data)
}

export const goodsDetail = (data) => {//商品详情
    return http.post('Goods/goodsDetail', data)
}

export const addShopCar = (data) => {//新增购物车
    return http.post('Shopcar/addShopCar', data)
}

export const shopCarList = (data) => {//购物车列表
    return http.post('Shopcar/shopCarList', data)
}

export const setNumber = (data) => {//设置购物车数量
    return http.post('Shopcar/setNumber', data)
}

export const delShopCar = (data) => {//删除购物车
    return http.post('Shopcar/delShopCar', data)
}

export const showConfirmOrder = (data) => {//确认订单展示页
    return http.post('Order/showConfirmOrder', data)
}

export const shopCarCommitOrder = (data) => {//购物车提交订单
    return http.post('Order/shopCarCommitOrder', data)
}

export const goodsCommitOrder = (data) => {//商品详情页提交订单
    return http.post('Order/goodsCommitOrder', data)
}

export const orderList = (data) => {//订单列表
    return http.post('Order/orderList', data)
}

export const sureOrder = (data) => {//确认订单
    return http.post('Order/sureOrder', data)
}

export const overOrder = (data) => {//确认收货
    return http.post('Order/overOrder', data)
}

export const cancelOrder = (data) => {//取消订单
    return http.post('Order/cancelOrder', data)
}

export const forgetPassword = (data) => {//忘记密码
    return http.post('Apilogin/forgetPassword', data)
}

export const orderDetail = (data) => {//订单详情
    return http.post('Order/orderDetail', data)
}

export const xunOrderList = (data) => {//询价单列表
    return http.post('Xun/xunOrderList', data)
}

export const showXunOrder = (data) => {//询价单详情
    return http.post('Xun/showXunOrder', data)
}

export const alreadyRead = (data) => {//询价单已读
    return http.post('Xun/alreadyRead', data)
}
export const commitXunOrder = (data) => {//提交询价单
    return http.post('Xun/commitXunOrder', data)
}
export const alreadyReadNumber = (data) => {//询价单所有已读
    return http.post('Xun/alreadyReadNumber', data)
}
export const getGoodsPriceBySpec = (data) => {//获取规格价格
    return http.post('Goods/getGoodsPriceBySpec', data)
}
export const getAllGoods = (data) => {//获取全部商品数据
    return http.post('Goods/getAllGoods', data)
}
export const getXunOrder = (data) => {//获取询价单新数据
    return http.post('Order/xunjiaCommitOrder', data)
}
export const getXunList = (data) => {//获取询价单新列表
    return http.post('Order/xunjiaList', data)
}

export const creatOrder = (data) => {//生成订单
    return http.post('Order/xunjiaCreateOrder', data)
}
export const getLikeGoods = (data) => {//生成订单
    return http.post('Goods/getLikeGoods', data)
}

export const newsList = (data) => {//新闻列表
    return http.post('Banner/newsList', data)
}

export const newInfo = (data) => {//新闻详情
    return http.post('Banner/newInfo', data)
}

export const defaultAddress = (data) => {//默认地址
    return http.post('Address/defaultAddress', data)
}
