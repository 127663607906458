import Vue from 'vue'
import VueRouter from 'vue-router'
import main from '../views/main.vue'
import productDetails from '../views/productDetails.vue'
import cate from '../views/cate.vue'
import collect from '../views/collect.vue'
import orderDetails from '../views/orderDetails.vue'
import register from '../views/register.vue'
import sureOrder from '../views/sureOrder.vue'
import login from '../views/login.vue'
import information from '../views/information.vue'
import forgetPwd from '../views/forgetPwd.vue'
import myAddress from '../views/myAddress.vue'
import orderCenter from '../views/orderCenter.vue'
import newAddress from '../views/newAddress.vue'
import myInfo from '../views/myInfo.vue'
import car from '../views/car.vue'
import inquiry from '../views/inquiry.vue'
import inquiryXq from '../views/inquiryXq.vue'

import intro from '../views/intro.vue'
import news from '../views/news.vue'
import connect from '../views/connect.vue'
import news_details from '../views/news_details.vue'
import buy from '../views/buy.vue'
import dPolicy from '../views/dPolicy.vue'
import sPolicy from '../views/sPolicy.vue'
import pPolicy from '../views/pPolicy.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '',
    name: 'main',
    component: main,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/productDetails/:id',
    name: 'productDetails',
    component: productDetails,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/cate',
    name: 'cate',
    component: cate,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/collect',
    name: 'collect',
    component: collect,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/intro',
    name: 'intro',
    component: intro,
  },

  {
    path: '/buy',
    name: 'buy',
    component: buy,
  },
  {
    path: '/dPolicy',
    name: 'dPolicy',
    component: dPolicy,
  },
  {
    path: '/sPolicy',
    name: 'sPolicy',
    component: sPolicy,
  },
  {
    path: '/pPolicy',
    name: 'pPolicy',
    component: pPolicy,
  },

  {
    path: '/news',
    name: 'news',
    component: news,
  },
  {
    path: '/news_details/:id',
    name: 'news_details',
    component: news_details,
  },
  {
    path: '/connect',
    name: 'connect',
    component: connect,
  },
  {
    path: '/orderDetails/:order_id',
    name: 'orderDetails',
    component: orderDetails,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/register',
    name: 'register',
    component: register
  },
  {
    path: '/information/:type/:email/:status?/:user_id?',
    name: 'information',
    component: information
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/forgetPwd',
    name: 'forgetPwd',
    component: forgetPwd
  },
  {
    path: '/car',
    name: 'car',
    component: car,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/sureOrder',
    name: 'sureOrder',
    component: sureOrder,
    meta:{
      requiresAuth: true
    }
  }, {
    path: '/myAddress',
    name: 'myAddress',
    component: myAddress,
    meta:{
      requiresAuth: true
    }
  }, {
    path: '/orderCenter/:id',
    name: 'orderCenter',
    component: orderCenter,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/newAddress',
    name: 'newAddress',
    component: newAddress,
    meta:{
      requiresAuth: true
    }
  },
  {
    path: '/newAddress/:id',
    name: 'newAddress1',
    component: newAddress,
    meta:{
      requiresAuth: true
    }
  }, {
    path: '/myInfo',
    name: 'myInfo',
    component: myInfo,
    meta:{
      requiresAuth: true
    }
  }, {
    path: '/inquiry',
    name: 'inquiry',
    component: inquiry,
    meta:{
      requiresAuth: true
    }
  }, {
    path: '/inquiryXq/:id',
    name: 'inquiryXq',
    component: inquiryXq,
    meta:{
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   let allUserMr = localStorage.getItem('allUserMr') // 这里应该是判断用户是否登录的逻辑，例如检查 token 或者 cookie
//   if (to.matched.some(record => record.meta.requiresAuth) && !allUserMr) {
//     next({ name: 'login' }) // 如果用户未登录，跳转到登录页
//   } else {
//     next() // 如果用户已登录，或者不需要登录，继续
//   }
// })
export default router
