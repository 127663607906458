import axios from 'axios'
import router from '../router'
import { Message } from 'element-ui'

const http = axios.create({
  // baseURL: 'https://niutop.xuanwumobile.com/',
  baseURL: 'http://phone.xuanwumobile.com/api',
  timeout: 5000,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
  }
})
// 添加请求拦截器
http.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么

    if (localStorage.getItem('allUserMr')) { 
      // config.data.user_id = 7
      config.data.user_id = 
        JSON.parse(localStorage.getItem('allUserMr')).user_id || 0
    } else {
      if (config.url != 'User/userBaseInfo' && config.url != 'User/commitCheck') {
        config.data.user_id = 0
      }
    }
    const lan = localStorage.getItem('lang') || 'en';
    config.data.lanType = lan == 'en' ? '0' : lan == 'es' ? '1' : '2';
    // console.warn('入参' + JSON.stringify(config))
    return config
  },
  function (error) {
    // 对请求错误做些什么
    return Promise.reject(error)
  }
)

// 添加响应拦截器
http.interceptors.response.use(
  function (response) {
    // console.warn('返回参:' + JSON.stringify(response))
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    if (response.data.status != 200) {

      if (response.data.status == 301 || response.data.status == 302 || response.data.status == 303 ) {
        router.push({ name: 'login' })
      } else {
        Message({
          message: response.data.msg,
          type: 'error'
        })
      }

    }
    return response.data
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    console.warn(JSON.stringify(error))
    return Promise.reject(error)
  }
)

export default http
