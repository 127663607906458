<template>
  <div style="background-color:#f3f3f3;">
    <div class="font-fff-18-500 align-center h42 bg-005BAC bold">{{ $t('headGRZXObj.zhzx') }}</div>
    <div class="box">
      <div class="pointer align-start" :class="{ active: num == 6 }" @click="fnOpen(6)">
        <el-image fit="cover" :src="require('@/assets/myInfo01.png')" class="img_icon"></el-image>
        {{ $t('headGRZXObj.jbxx') }}
      </div>
      <div class="pointer align-start" :class="{ active: num == 7 }" @click="fnOpen(7)"><el-image fit="cover"
          :src="require('@/assets/myInfo02.png')" class="img_icon"></el-image>{{ $t('headGRZXObj.dzgl') }}</div>
      <div class="pointer align-start" :class="{ active: num == 8 }" @click="fnOpen(8)"><el-image fit="cover"
          :src="require('@/assets/myInfo03.png')" class="img_icon"></el-image>{{ $t('headGRZXObj.wdsc') }}</div>
    </div>
    <div class="font-fff-18-500 align-center h42 bg-005BAC bold">{{ $t('headGRZXObj.ddzx') }}</div>
    <div class="box">
      <div class="pointer align-start" :class="{ active: num == 0 }" @click="fnOpen(0)"><el-image fit="cover"
          :src="require('@/assets/myInfo04.png')" class="img_icon"></el-image>{{ $t('headGRZXObj.qbdd') }}</div>
      <div class="pointer align-start" :class="{ active: num == 1 }" @click="fnOpen(1)"><el-image fit="cover"
          :src="require('@/assets/myInfo05.png')" class="img_icon"></el-image>{{ $t('headGRZXObj.dqr') }}</div>
      <div class="pointer align-start" :class="{ active: num == 2 }" @click="fnOpen(2)"><el-image fit="cover"
          :src="require('@/assets/myInfo06.png')" class="img_icon"></el-image>{{ $t('headGRZXObj.dfk') }}</div>
      <div class="pointer align-start" :class="{ active: num == 3 }" @click="fnOpen(3)"><el-image fit="cover"
          :src="require('@/assets/myInfo07.png')" class="img_icon"></el-image>{{ $t('headGRZXObj.dfh') }}</div>
      <div class="pointer align-start" :class="{ active: num == 4 }" @click="fnOpen(4)"><el-image fit="cover"
          :src="require('@/assets/myInfo08.png')" class="img_icon"></el-image>{{ $t('headGRZXObj.dsh') }}</div>
      <div class="pointer align-start" :class="{ active: num == 5 }" @click="fnOpen(5)"><el-image fit="cover"
          :src="require('@/assets/myInfo09.png')" class="img_icon"></el-image>{{ $t('headGRZXObj.ywc') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    num111: {
      Type: Number,
      default: 999,
    },
  },
  data() {
    return {
      num: -1
    }
  },
  methods: {
    fnOpen(type) {
      if (type == 6) {
        this.$router.push({
          name: 'myInfo'
        })
      }
      if (type == 7) {
        this.$router.push({
          name: 'myAddress'
        })
      }
      if (type == 8) {
        this.$router.push({
          name: 'collect'
        })
      }
      if (type < 6) {
        this.num = type
        this.$router.push({
          name: 'orderCenter',
          params: {
            id: type
          },
        })
        this.$emit('update', type)
      }
    }
  },
  created() {
    this.$bus.$off("orderType")
    this.$bus.$on("orderType", (val) => {
      this.num = val || "";
      console.log(val);
    });
  },
  mounted() {
    if (this.num111 != 999) {
      this.num = this.num111
    } else {
      let can = this.$route.params.id
      this.num = can
    }
  }
}
</script>


<style scoped>
.box {
  padding: 10px;
  /* line-height: 25px; */
  font-weight: 400;
  font-size: 14px;
  color: #999999;
  /* line-height: 48px; */
}

.box>div.pointer {
  min-height: 45px;
  border-bottom: 1px dotted #ccc;
}

.box>div.pointer:last-child {
  border-bottom: none;
}

.img_icon {
  width: 26px;
  height: 26px;
  margin-right: 8px;
}

.box>div.pointer:hover,
.box>div.pointer.active {
  /* font-weight: 500; */
  color: #005BAC;
}
</style>